import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import { plugin as formkitPlugin, defaultConfig } from '@formkit/vue';
import { de } from '@formkit/i18n';
import { Slide } from 'vue3-burger-menu';
import App from './App.vue';
import Home from '@/views/Home.vue';
import Klavier from '@/views/Klavier.vue';
import Portrait from '@/views/Portrait.vue';
import Leistungen from '@/views/Leistungen.vue';
import Kontakt from '@/views/Kontakt.vue';
import Impressum from '@/views/Impressum.vue';
import Datenschutz from '@/views/Datenschutz.vue';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import vueCookieComply from 'vue-cookie-comply';
import 'vue-cookie-comply/dist/style.css';
import axios from 'axios';
import VueAxios from 'vue-axios';

const app = createApp(App);
app.use(VueAxios, axios);

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'Home',
      components: {
        header: Header,
        default: Home,
        footer: Footer,
      },
    },
    {
      path: '/klavier',
      name: 'Klavier',
      components: {
        header: Header,
        default: Klavier,
        footer: Footer,
      },
    },
    {
      path: '/portrait',
      name: 'Portrait',
      components: {
        header: Header,
        default: Portrait,
        footer: Footer,
      },
    },
    {
      path: '/leistungen',
      name: 'Leistungen',
      components: {
        header: Header,
        default: Leistungen,
        footer: Footer,
      },
    },
    {
      path: '/kontakt',
      name: 'Kontakt',
      components: {
        header: Header,
        default: Kontakt,
        footer: Footer,
      },
    },
    {
      path: '/impressum',
      name: 'Impressum',
      components: {
        header: Header,
        default: Impressum,
        footer: Footer,
      },
    },
    {
      path: '/datenschutz',
      name: 'Datenschutz',
      components: {
        header: Header,
        default: Datenschutz,
        footer: Footer,
      },
    },
  ],
});

const formkitConfig = defaultConfig();
formkitConfig.locales = { de };
formkitConfig.locale = 'de';

app
  .use(router)
  .use(formkitPlugin, formkitConfig) // Verwende die konfigurierte FormKit-Konfiguration
  .use(vueCookieComply)
  .component('slide', Slide) // Registriere dein Slide-Komponente
  .mount('#app');
