<template>
    <default>
   <div class="piano">
     <div class="cnthero">
     <div class="hslogan-leist">
       <h1 class="heroleist">Das Klavier<br>braucht Pflege</h1>
     </div>
     <div class="subhead">
       <h1 class="herohtwo">Klavierstimmen</h1>
       <img src="../assets/arrow_x.png" style="align-self: center;"/>
     </div>
    <div id="scroll" style="margin: 0 auto;">
       <a @click="scrollToElement" style="position: absolute; bottom: 2em;"><img src="../assets/arrow.png" /></a>
   </div>
 </div>
 </div>
    <div class="main-kontent" style="width: 1440px; margin:0 auto">
     <div id="imssumCont" ref="targetRef">
     <div style="margin: 4em 2em;">
     <h1 style="text-align: left; font-size: 28px; font-family: 'Sienna-Bold', sans-serif; margin: 0; line-height: 42px;">Impressum</h1>
 <h2 style="text-align: left; font-size: 21px; font-family: 'Orto-Bold', sans-serif; margin: 0; line-height: 30px;"></h2>
 <p style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; color: #000; line-height: 26px;">
Verantwortlich für den Inhalt dieser Seite ist:<br>
Arne Assmuth<br>
Isselstraße 24<br>
64297 Darmstadt</p>
<h3>Haftungsausschluss</h3>
<p style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; color: #000; line-height: 26px;">
Haftung für Inhalte Wir bemühen uns die Inhalte unserer Seite aktuell zu halten. Trotz sorgfältiger Bearbeitung bleibt eine Haftung ausgeschlossen.
 </p>
 <p style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; color: #000; line-height: 26px;">Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich.
</p>
<p style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; color: #000; line-height: 26px;">Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen. Bei bekannt werden von Rechtsverletzungen, werden wir diese Inhalte umgehend entfernen. Eine diesbezügliche Haftung übernehmen wir erst ab dem Zeitpunkt der Kenntnis einer möglichen Rechtsverletzung.
</p>
<h3>Haftung für Links</h3>
<p style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; color: #000; line-height: 26px;">
Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Für die Inhalte und die Richtigkeit der Informationen verlinkter Websites fremder Informationsanbieter wird keine Gewähr übernommen.
Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße OHNE BEANSTANDUNG überprüft. Bei bekannt werden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
</p>
<h3>Urheberrecht</h3>
<p style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; color: #000; line-height: 26px;">
Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
</p>
     </div>
     
     <div style="display: inline-grid;">
 <div class="foursquare" style="justify-self: right;">
   <div style="display: flex;">
     <div style="  width: 50%; display: flex; margin-right: 12%;">
     <img src="../assets/icon-fon.png" style="height: fit-content;"><p style="font-family: 'Orto-Bold'; line-height: 42px; color: #fff; font-size: 28px; margin: 0px 0 0 12px;">0171-4131710</p>
   </div>
   <div style="width: 38%; display: flex;">
     <img src="../assets/icon-envalope.png" style="height: fit-content;"><p style="font-family: 'Orto-Bold'; line-height: 42px; color: #fff; font-size: 28px; margin: 0px 0 0 12px;">KONTAKT</p>
   </div>
   </div>
   <div style="margin-top: 4em;">
   <p style="font-family: 'Sienna-Bold', sans-serif; font-size: 36px; line-height: 52px; text-align: right; font-weight: 600; color: #fff; margin: 0;">Arne Assmuth<br>Isselstraße 24<br>Darmstadt
 </p>
 </div>
     </div>
     <div>
     <h3>Datenschutz</h3>
<p style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; color: #000; line-height: 26px;">
Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich. Eine Vertraulichkeit im Hinblick auf die Datenschutzbestimmungen wird nur unter der vorstehenden Einschränkung gewährleistet. Insbesondere sollen alle Mitteilungen von personenbezogenen Daten über das Internet nur erfolgen, soweit nicht Rechte Dritter berührt werden. Es sei denn der Dritte hat in Kenntnis der vorstehenden Sicherheitslücken ebenfalls seine Zustimmung erklärt. Eine Haftung des Seitenbetreibers wird für die durch solche Sicherheitslücken entstehenden Schäden oder Unterlassungsansprüche ausgeschlossen.
Der Nutzung von allen veröffentlichten Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich angeforderter Werbung wird widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.
</p>
<h3>Bildnachweis</h3>
<p style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; color: #000; line-height: 26px;">
| ©shutterstock.com/leungchopan |<br>
| ©shutterstock.com/Kei Shooting |<br>
| ©shutterstock.com/PeterMooij |<br>
| ©shutterstock.com/Telekhovskyi |<br>
| ©shutterstock.com/Wongsiri Subhayon |<br>
| ©shutterstock.com/Olivier Le Queinec |<br>
| ©shutterstock.com/PeterMooij |
   </p>
</div>
</div>
   </div>
   
<div class="overroll">
 <div class="zweier">
     <h1 style="font-family: 'Sienna-Bold', serif;"><span class="n-alternate">N</span>ehmen <span class="s-alternate">S</span>ie <span class="k-alternate">K</span>ontakt <span class="a-alternate">A</span>UF und <span class="l-alternate">l</span>assen Sie Ihr <span class="k-alternate">K</span>lavier <span class="s-alternate">S</span>ichten</h1>
 </div>
 <div style="width: 30%; float: left; margin-left: 6%; margin-right: 4%; margin-top: 28px;">
 <div style="width: 20%; float: left;"><img src="../assets/hoerer-g.png" style="width:60px;"></div>
 <div style="width: 100%;"><span style="font-family: 'Orto-Bold', sans-serif; font-size: 26px; line-height:38px; color: #000;">Telefon</span><br>
 <span style="font-family: 'Orto-Medium', sans-serif; font-size: 26px; line-height:38px; color: #000;">+49 (0)170 - 4131 710</span></div>
 </div>
 <div style="width: 28%; float: left; margin-right: 2%; margin-top: 28px;">
 <div style="width: 20%; float: left;"><img src="../assets/formular-g.png" style="width:60px;"></div>
 <div style="width: 100%;"><a style="text-decoration: none; color: #666666;" href="#/kontakt"><span style="font-family: 'Orto-Bold', sans-serif; font-size: 26px; line-height:38px; color: #000;">Kontaktformular</span><br>
 <span style="font-family: 'Orto-Medium', sans-serif; font-size: 26px; line-height:38px; color: #000;">Termin anbieten</span></a></div>
 </div>
 <div style="width: 28%; float: left; margin-right: 0%; margin-top: 28px;">
 <div style="width: 20%; float: left;"><img src="../assets/at-g.png" style="width:60px;"></div>
 <div style="width: 100%;"><span style="font-family: 'Orto-Bold', sans-serif; font-size: 26px; line-height:38px; color: #000;">E-Mail</span><br>
 <span style="font-weight: bold; font-size: 14px;"><a style="font-family: 'Orto-Medium', sans-serif; font-size: 19px; line-height: 38px; display: inline; color: #000;" href="mailto:info@klavierstimmen-braunschweig.de">klavierstimmen@braunschweig</a></span></div>
 </div>
 </div>
 
   </div>
   </default>
 </template>
 
 <script>
 export default {
   methods: {
     scrollToElement() {
       const element = document.getElementById('imssumCont');
       if (element) {
         element.scrollIntoView({ behavior: 'smooth' });
       }
     }
   }
 }
 </script>
 
 <style>
 .piano {
   background-image: url('../assets/leistungen_1.jpg');
   height: 85vH;
   background-size: cover;
   background-position: center;
   margin: 0 auto;
   display: grid;
   /*
   margin-left: calc((100% - 1440px) / 2); /* Berechnung des Überschusses über die 1440px-Seitenbreite */
   /* margin-right: calc((100% - 1440px) / 2); /* Berechnung des Überschusses über die 1440px-Seitenbreite */
 }
 .pianoimg {
    background-image: url(../assets/home_2.jpg);
 }
 .hslogan-leist {
   display: inline-grid;
   align-self: center;
   margin: 0 auto;
 }
 h1.heroleist {
   font-family: "Sienna-Bold";
   font-size: 8em;
   color: #fff;
   line-height: 1.1em;
   margin: 0;
   text-align: center;
 }
 #imssumCont {
   display: grid;
   grid-template-columns: 1.0fr 1.0fr;
   grid-gap: 1em;
   margin: 0 0 2em 0;
   grid-gap: 4em;
 }
 .wellen {
   background-image: url(../assets/wellen_trans.png);
   background-repeat: no-repeat;
   background-position: 10px 50px;
 }
 </style>