<template>
    <footer>
      <div id="notes" style="text-align: center; margin-bottom: 4.0em;"><img src="../assets/notes.png"></div>
        <div class="footer" style="display: grid; grid-template-columns: 0.8fr 0.5fr 0.5fr 1.0fr; justify-items: center;">
          <div id="footer" style="float:left; margin-right:0%;margin-left:0%;color: #fff;">
<div style="width:100%;float:left;text-align: right;right: 52%; position: relative;">
  <h6 style="font-family: 'Sienna-Medium', sans serif; font-size:21px;font-weight:700;line-height:1.8em; text-transform: uppercase;">Kontakt</h6></div>
<div style="font-family: 'Sienna-Medium', sans serif; font-size: 18px ;width:48%;float:left;text-align:right;">
  <p style="margin: 0; line-height: 1.4em;">Arne Assmuth<br>Isselstrasse 24<br>64297 Darmstadt</p>
</div>
<div style="font-family: 'Sienna-Medium', sans-serif; font-size: 18px; width:48%;float:right;">
  <p style="margin: 0; line-height: 1.4em;">Fon: 0170 - 413 1710<br><span><a href="mailto:info@klavierstimmen-braunschweig.de" style="text-decoration: underline; color: #fff;">info@klavierstimmen-
    <br>braunschweig.de</a></span></p></div>
</div>
<div id="footer" style="float:left; margin-right: 3%; margin-left: 6%; color: #fff; padding-left:1%;padding-bottom: 2%;"><h6 style="font-family: 'Orto-Medium', sans-serif; font-size: 21px; line-height:1.8em; text-transform: uppercase;">Leistungen</h6>
  <ul>
<div class="custom-menu-class"><ul id="menu-footer-one" class="menu"><li id="menu-item-210" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-210"><RouterLink to="/leistungen/">Stimmen</RouterLink></li>
<li id="menu-item-211" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-211"><RouterLink to="/leistungen/">Unterrichten</RouterLink></li>
<li id="menu-item-212" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-212"><RouterLink to="/leistungen/" data-ps2id-api="true">Vermitteln</RouterLink></li>
</ul></div></ul></div>
<div id="footer" style="float:left; margin-right:1%; margin-left:4%; color: #fff; padding-left: 1%;padding-bottom: 2%;">
  <h6 style="font-family: 'Orto-Medium', sans-serif; font-size: 21px; line-height:1.8em; text-transform: uppercase;">Rechtliches</h6>
<ul><div class="custom-menu-class"><ul id="menu-footer-two" class="menu"><li id="menu-item-204" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-204"><RouterLink to="/kontakt/">Kontakt</RouterLink></li>
<li id="menu-item-205" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-205"><RouterLink to="/impressum/" data-ps2id-api="true">Impressum</RouterLink></li>
<li id="menu-item-206" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-206"><RouterLink to="/datenschutz/" data-ps2id-api="true">Datenschutz</RouterLink></li>
</ul></div></ul></div>
<div id="footer" style="display: grid; grid-template-rows: 1fr 1fr; grid-gap: 4em; float:left;margin-left:6%;color: #fff;">
  <div>
  <h6 style="font-family: 'Orto-Medium', sans-serif; font-size:21px;font-weight:700;line-height:1.8em; text-transform: uppercase;">Zertifizierung</h6>
  <p style="font-family: 'Orto', sans-serif; font-size: 18px; margin: 0; line-height: 1.4em;">Eingetragener Betrieb in der<br>Handelskammer Rhein-Main</p>
</div>
  <div id="social" style="margin-top: 3%; color: #fff;">
<h6 style="font-family: 'Orto-Medium', sans-serif; font-size:21px;font-weight:700;line-height:1.8em; text-transform: uppercase;">Folge uns</h6>
<div style="width:; float: left;  color: #fff; display: flex;">
  <img src="../assets/facebook.png" style="margin-right: 14%;">
  <img src="../assets/instagram.png" style="margin-left: 14%;"></div>
</div>
</div>
</div>
<div id="theme-attribution" style="color: #fff; color: #fff; width: 1440px; margin: 0 auto; font-family: 'Orto', sans-serif; font-size: 14px;"><a href=""></a>© ARNE ASSMUTH <span>KLAVIERSTIMMER<span></span></span></div>
</footer>
  </template>
  
  <script>
  export default {
    // Component options
    name: 'Header',
  }
  </script>
  
  <style>
  footer {
    background-color: #1a1d1d;
    padding: 60px 30px 15px 30px;
  }
  .footer {
    width: 100%;
    max-width: 1440px;
    margin: 4em auto 2em;
}
ul {
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
}
h6 {
  margin: 0;
  line-height: 1.8em;
  color: rgb(160, 160, 160)
}
a {
  font-family: 'Orto-Medium', sans-serif;
  font-size: 18px;
  text-decoration: none;
  color: #fff;
  line-height: 1.6em;
}
li a:hover {
  color: #32a852 !important;
}
  </style>
  