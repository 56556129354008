<template>
  <div class="home">
  <div class="cnthero">
  <div class="heroslogan">
    <h1 class="herohone">Das Klavier<br>braucht Pflege</h1>
  </div>
  <div class="subhead">
    <h1 class="herohtwo"><a class="herolink" href="/leistungen">Klavierstimmen</a></h1>
    <img src="../assets/arrow_x.png" style="align-self: center;"/>
  </div>
 <div id="scroll" style="margin: 0 auto;">
    <a @click="scrollToElement" style="position: absolute; bottom: 2em;"><img src="../assets/arrow.png" /></a>
</div>
</div>
</div>
<div class="main-kontent" style="width: 1440px; margin:0 auto">
  <div id="allCars" ref="targetRef">
<div class="foursquare">
<div style="display: flex;">
  <div style="  width: 50%; display: flex; margin-right: 12%;">
  <img src="../assets/icon-fon.png" style="height: fit-content;"><p style="font-family: 'Orto-Bold';line-height: 42px; color: #fff; font-size: 28px; margin: 0px 0 0 12px;">0171-4131710</p>
</div>
<div style="width: 38%; display: flex;">
  <img src="../assets/icon-envalope.png" style="height: fit-content;"><p style="font-family: 'Orto-Bold'; line-height: 42px; color: #fff; font-size: 28px; margin: 0px 0 0 12px;">KONTAKT</p>
</div>
</div>
<div style="text-align: left; margin-top: 4em;">
<img src="../assets/rede.png" />
</div>
<div>
<p style="font-family: 'Sienna-Bold', sans-serif; font-size: 36px; line-height: 60px; text-align: right; font-weight: 600; color: #fff; margin: 0;">Der Saitenzug und die Spielbelastung sorgen dafür, dass sich das Klavier verstimmt.</p>
<p style="font-family: 'Orto-Bold', sans-serif; font-size: 21px; text-align: right; font-weight: 600; color: #fff; text-align: right; margin: 0;">Arne Assmuth</p>
</div>
  </div>
  <div style="margin: 2em 2em;">
  <h1 style="text-align: left; font-size: 28px; font-family: 'Orto-Bold', sans-serif; margin: 0; line-height: 42px;">Wartung und Betreuung von Saiteninstrumenten</h1>
<h2 style="text-align: left; font-size: 21px; font-family: 'Orto-Bold', sans-serif; margin: 0; line-height: 30px;">Ich bedanke mich für Ihr Interesse.</h2>
<p style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; color: #000; line-height: 26px;">Freuen würde ich mich, wenn Sie mit mir in Kontakt treten. 
  </p>
<p style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; color: #000; line-height: 26px;">Sie erhalten von mir grundsätzlich Festpreisangebote inklusive aller Nebenleistungen wie z.B. Reiskosten. Sie wissen also immer vorher, welche Kosten auf Sie zukommen. Bei aufwändigeren Reparaturanfragen erhalten Sie immer einen Kostenvorschlag, bevor ich tätig werde.
Die Bezahlung erfolgt direkt vor Ort in Bar oder per EC-Karte. Für den Fall, dass ich teurere Ersatzteile für Sie beschaffen muss, behalte ich mir vor, eine Anzahlung zu erbitten.</p>
  </div>
  </div>
  <div style="display: grid; grid-template-columns: 0.8fr 1.2fr; grid-gap: 4em;">
      <div style="margin: 2em 2em;"><p style="text-align: right; font-family: 'Orto', sans-serif; font-size: 18px; color: #000; line-height: 26px; margin: 0;"><strong>Arne Assmuth<br>
              Isselstraße 24<br>
              64297 Darmstadt<br>
              Mobil 0170 / 4131710<br>
              info@klavierstimmen-braunschweig.de</strong></p>
</div>
      <div style="margin: 2em 0em;">
          <h2 style="text-align: left; font-size: 21px; font-family: 'Orto-Bold', sans-serif; margin: 0; line-height: 30px;"> Kontaktformular </h2>
    
          <div>
      <FormKit
  type="form"
  :config="{ validationVisibility: 'submit' }"
  @submit="handleSubmit"
  ref="myForm" 
>
<FormKit
      name="anliegen"      
      v-model="formData.anliegen"
      type="checkbox"
      label="Anliegen"
      :options="['Anfrage', 'Rückrufbitte']"
      validation="required|max:1"
      validation-visibility="dirty"
    />
    <div style="border-bottom: 1px dotted #000;"></div>
    <div style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 2em;">
      <FormKit
        name="name"
        v-model="formData.name"
        validation="required"
        label="Ihr Name"
      />
      <div></div>
      </div>
      <div style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 2em;">
      <FormKit
        name="email"
        v-model="formData.email"
        validation="required|email"
        label="Ihre E-Mail-Adresse"
        validation-visibility="dirty"
      />
      <FormKit
        name="telefon"
        v-model="formData.telefon"
        label="Telefon"
      />
    </div>
    <div style="border-bottom: 1px dotted #000;"></div>
      <FormKit
      name="intouch"      
      v-model="formData.intouch"
      type="checkbox"
      label="Kontaktaufnahme"
      :options="['einfach probieren', 'tagsüber', 'abends']"
    />
    <FormKit
        type="textarea"
        name="termin"
        rows="10"
        v-model="formData.termin"
        label="Unverbindlicher Terminwunsch"
      />
      <FormKit
        type="textarea"
        name="message"
        rows="10"
        v-model="formData.message"
        label="Ihre Nachricht"
      />
      <FormKit
      type="checkbox"
      label="Ich habe die Erklärungen zum Datenschutz zur Kenntnis genommen und akzeptiere diese."
      name="terms"
      v-model="formData.terms"
      validation=""
    />
  </FormKit>

  </div>
   
  </div>
</div>
</div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      formData: {
        name: '',
        email: '',
        telefon: '',
        intouch: '',
        termin: '',
        message: '',
        anliegen: ['Anfrage'], // Option "Anfrage" ist vorab ausgewählt
        terms: true // Checkbox "terms" ist vorab ausgewählt
      }
    };
  },
  methods: {
    async handleSubmit() {
      if (!this.formData.terms) {
        // Die Checkbox "Terms" wurde nicht ausgewählt
        alert('Bitte akzeptieren Sie die Bedingungen zum Datenschutz');
        return; // Das Formular wird nicht verschickt
     }
     // Our api request:
     await new Promise((r) => setTimeout(r, 1000))
     // resetting your v-model:
      this.complete = true
      this.$formkit.reset('myForm');
      
      try {
        const response = await axios.post('https://klavierstimmen-braunschweig.de:9000/send-email', this.formData);
        console.log(response.data);
      } catch (error) {
        console.error(error);
      }
    },
    scrollToElement() {
      const element = document.getElementById('allCars');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    },
  }
};
</script>

<style>
ul.formkit-options {
  display: grid;
  grid-template-columns: auto auto auto;
}
.formkit-outer {
  margin: 2em 0em;
}
input[name="name"] {
  width: 100%;
}
input[name="email"] {
  width: 100%;
}
input[name="telefon"] {
  width: 100%;
}
textarea {
  width: 100%;
}
</style>