<template>
  <default>
  <div class="home">
    <div class="cnthero">
    <div class="heroslogan">
      <h1 class="herohone">Das Klavier<br>braucht Pflege</h1>
    </div>
    <div class="subhead">
      <h1 class="herohtwo"><a class="herolink" href="/leistungen">Klavierstimmen</a></h1>
      <img src="../assets/arrow_x.png" style="align-self: center;"/>
    </div>
   <div id="scroll" style="margin: 0 auto;">
      <a @click="handleClick" style="position: absolute; bottom: 2em;"><img src="../assets/arrow.png" /></a>
  </div>
</div>
</div>
   <div class="main-kontent" style="max-width: 1440px; width: 100%; margin:0 auto">
    <div id="allCars" ref="targetRef">
<div class="foursquare">
  <div style="display: flex;">
    <div style="  width: 50%; display: flex; margin-right: 12%;">
    <img src="../assets/icon-fon.png" style="height: fit-content;"><p style="font-family: 'Orto-Bold'; line-height: 42px; color: #fff; font-size: 28px; margin: 0px 0 0 12px;">0171-4131710</p>
  </div>
  <div style="width: 38%; display: flex;">
    <img src="../assets/icon-envalope.png" style="height: fit-content;"><p style="font-family: 'Orto-Bold'; line-height: 42px; color: #fff; font-size: 28px; margin: 0px 0 0 12px;">KONTAKT</p>
  </div>
  </div>
<div style="text-align: left; margin-top: 4em;">
<img src="../assets/rede.png" />
</div>
  <div>
  <p style="font-family: 'Sienna-Bold', sans-serif; font-size: 36px; line-height: 60px; text-align: right; font-weight: 600; color: #fff; margin: 0;">Der Saitenzug und die Spielbelastung sorgen dafür, dass sich das Klavier verstimmt.</p>
<p style="font-family: 'Orto-Bold', sans-serif; font-size: 21px; text-align: right; font-weight: 600; color: #fff; text-align: right; margin: 0;">Arne Assmuth</p>
</div>
    </div>
    <div style="margin: 2em 2em;">
    <h1 style="text-align: left; font-size: 28px; font-family: 'Orto-Bold', sans-serif; margin: 0; line-height: 42px;">Wartung und Betreuung von Saiteninstrumenten</h1>
<h2 style="text-align: left; font-size: 21px; font-family: 'Orto-Bold', sans-serif; margin: 0; line-height: 30px;">Fachgerechtes Stimmen, Regulieren und Intunieren von Flügeln und Klavieren</h2>
<p style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; color: #000; line-height: 26px;">Die Wartung und Betreuung von Saiteninstrumenten ist von entscheidender Bedeutung, um ihre optimale Klangqualität und Spielbarkeit zu gewährleisten.
Insbesondere bei Flügeln und Klavieren ist fachgerechtes Stimmen, Regulieren und Intonieren unerlässlich, um das volle Potenzial dieser wundervollen Instrumente zu entfalten.
Das Stimmen eines Flügels oder Klaviers ist ein komplexer Prozess, der die Feinabstimmung der Saitenspannung erfordert, um die richtige Tonhöhe zu erzielen.</p>
<p style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; color: #000; line-height: 26px;">Ein gut gestimmtes Instrument sorgt für harmonische Klänge und ermöglicht dem Musiker, seine Emotionen und Ausdruckskraft voll zur Geltung zu bringen. Regelmäßiges Stimmen ist daher essenziell, da Temperatur- und Feuchtigkeitsschwankungen die Saitenspannung beeinflussen können.
Zusätzlich zum Stimmen ist die Regulierung ein weiterer wichtiger Aspekt der Wartung. Hierbei werden die Mechanik und die Tasten des Instruments justiert, um eine gleichmäßige und präzise Spielart sicherzustellen. Ein gut reguliertes Klavier oder Flügel ermöglicht dem Pianisten eine exakte Kontrolle über Anschlag und Artikulation, was sich direkt auf die musikalische Interpretation auswirkt.
 </p>
    </div>
  </div>
  <div style="display: grid; grid-template-columns: 1.5fr 1fr; margin: 8em 0 0 0; grid-gap: 4em;">
<div class="firstimg" style="display: inline-grid; background-size: auto 620px; background-repeat: no-repeat; height: 520px; position:relative;">
<img src="../assets/tiles-r.png" style="position: absolute; height: 100%;">
<p style="font-family: 'Sienna-Medium', sans-serif; color:#fff; transform: rotate(270deg); font-size: 32px; margin: 4% 0 0 -50px; line-height: 32px; width: auto; position: absolute; top: 80px; letter-spacing: 0.05em; position: absolute;">STIMMEN</p>
<img src="../assets/klaviatur.png" style="bottom: 14px; position: absolute; opacity: 0.8;">
</div>
<div style="display: grid; grid-template-rows: auto 1fr;">
  <div>
  <img src="../assets/icon_hammer_4c.png" />
</div>
<div style="display: inline-grid; align-self: center;">
<h2 style="text-align: left; font-size: 24px; font-family: 'Orto-Bold', sans-serif; margin: 0; line-height: 32px;">Regelmäßig am Klavier arbeiten um die Töne zu halten</h2>
<p style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 26px;">Klavierstimmung bedeutet, die Spannung der Saiten so einzustellen, dass sie klanglich zueinander passen.
  Regulieren bedeutet, die Mechanik so abzustimmen, dass das gewünschte Klangbild entsteht.
Intonieren bedeutet, das Klangbild durch Behandlung des Filzes an den Hammerköpfen zu beeinflussen.
Inklusive ist dabei immer eine fachgerechte Wartung und eine persönliche Beratung zur Pflege Ihres Instrumentes.</p>
<a href="/leistungen" style="text-align: left;font-family: 'Orto-Bold', sans-serif; font-size: 18px !important; line-height: 26px; text-decoration: none; color: #000;">jetzt das Saiteninstrument stimmen lassen ></a>
</div>
</div>
  </div>
  <div style="display: grid; grid-template-columns: 1fr 1.5fr; margin: 12em 0 0 0; grid-gap: 4em;">
    <div style="display: grid; grid-template-rows: auto 1fr;">
  <div>
  <img src="../assets/icon_hammer_4c.png" />
</div>
<div style="display: inline-grid; align-self: center;">
<h2 style="text-align: left; font-size: 24px; font-family: 'Orto-Bold', sans-serif; margin: 0; line-height: 32px;">Mängel am Klavier direkt vor Ort erkennen und beheben</h2>
<p style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 26px;">Als Experte für Klavierreparaturen erkenne ich die Mängel an Ihrem Saiteninstrument. Mit meinem Fachwissen und meiner Erfahrung lassen sich die Repaturen meist kurzfristig vor ausführen.
  So können Sie Ihr Instrument schnell wieder optimal nutzen und seine wunderbaren Klänge genießen.
  Vertrauen Sie auf meine Kompetenz, um Ihr Klavier in Topform zu halten. </p>
<a href="/leistungen" style="text-align: left;font-family: 'Orto-Bold', sans-serif; font-size: 18px !important; line-height: 26px; text-decoration: none; color: #000;">jetzt das Saiteninstrument reparieren lassen ></a>
</div>
</div>
<div class="secondimg" style="display: inline-grid; background-size: auto 620px; background-repeat: no-repeat; height: 520px; position:relative; background-position: right top; justify-items: end;">
<img src="../assets/tiles-r.png" style="position: absolute; height: 100%;">
<p style="font-family: 'Sienna-Medium', sans-serif; color:#fff; transform: rotate(270deg); font-size: 32px; margin: 4% -88px 0px 0; line-height: 32px; width: auto; position: absolute; top: 80px; letter-spacing: 0.05em; position: absolute; text-transform: uppercase;">Reparieren</p>
<img src="../assets/klaviatur.png" style="bottom: 14px; position: absolute; opacity: 0.8; rotate: 180deg;">
</div>
  </div>
  <div style="display: grid; grid-template-columns: 1.5fr 1fr; margin: 12em 0 0 0; grid-gap: 4em;">
<div class="thirdimg" style="display: inline-grid; background-size: auto 620px; background-repeat: no-repeat; height: 520px; position:relative;">
<img src="../assets/tiles-r.png" style="position: absolute; height: 100%;">
<p style="font-family: 'Sienna-Medium', sans-serif; color:#fff; transform: rotate(270deg); font-size: 32px; margin: 8% 0px 0px -100px; line-height: 32px; width: auto; position: absolute; top: 80px; letter-spacing: 0.05em; position: absolute; text-transform: uppercase;">Begutachten</p>
<img src="../assets/klaviatur.png" style="bottom: 14px; position: absolute; opacity: 0.8;">
</div>
<div style="display: grid; grid-template-rows: auto 1fr;">
  <div>
  <img src="../assets/icon_hammer_4c.png" />
</div>
<div style="display: inline-grid; align-self: center;">
<h2 style="text-align: left; font-size: 24px; font-family: 'Orto-Bold', sans-serif; margin: 0; line-height: 32px;">Begutachten - Der Wert eines Saiteninstruments ergibt sich aus vielen Faktoren</h2>
<p style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 26px;">Das Begutachten von Saiteninstrumenten ist eine komplexe Aufgabe, da der Wert eines Instruments von vielen Faktoren abhängt.
  Dazu zählen die Marke, das Baujahr, der Zustand, die Klangqualität und die Seltenheit des Instruments. Auch die Geschichte und frühere Restaurierungen spielen eine Rolle. Als erfahrener Experte bewerte ich Ihr Saiteninstrument objektiv und transparent.
  Vertrauen Sie meiner Expertise, um den wahren Wert Ihres Instruments zu ermitteln.</p>
<a href="/leistungen" style="text-align: left;font-family: 'Orto-Bold', sans-serif; font-size: 18px !important; line-height: 26px; text-decoration: none; color: #000;">jetzt das Saiteninstrument begutachten lassen ></a>
</div>
</div>
  </div>

<div style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 0em 8em; grid-template-rows: auto auto; margin: 8em 0 0 0;">
  <div style="">
<h2 style="text-align: left; font-size: 24px; font-family: 'Orto-Bold', sans-serif; margin: 0px; line-height: 32px;">Herzlich Willkommen</h2>
</div>
<div></div>
<div>
<p style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 26px;">Auf den folgenden Seiten möchte ich Ihnen vorstellen, welche Dienstleistungen ich Ihnen anbiete.</p>
<p style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 26px;">Ich begutachte für Sie den Flügel oder das Klavier und nehme Ihr Instrument fachgerecht unter die Lupe. Sollte Handlungsbedarf bestehen, kann ich Ihnen in der Regel direkt vor Ort erläutern, welche Arbeiten an dem Saiteninstrument notwendig sind.
</p>

<p style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 26px;">Häufig reichen ein paar Handgriffe an der richtigen Stelle, um Ihr Klavier wieder gängig zu machen. In anderen Fällen kann es aber natürlich vorkommen, dass schadhafte Teile ersetzt werden müssen. Wirbel, Saiten oder Hammerköpfe lassen sich schnell und preiswert reparieren. Solltes es doch einmal zu größeren Problemen kommen, erstelle ich Ihnen einen Kostenvoranschlag und wir stimmen das weitere Vorgehen in aller Ruhe ab.
</p>
</div>
<div>
<p style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 26px;">Für die Wartung und Betreuung von Saiteninstrumenten ist die Unterstützung eines erfahrenen Klavierbauers oder Instrumententechnikers unerlässlich.
  Mit fundiertem Wissen und geschickten Händen gewährleiste ich die präzise Umsetzung der notwendigen Anpassungen. Fachgerechtes Stimmen, Regulieren und Intonieren gehören dabei zu meinen Spezialgebieten.</p>

<p style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 26px;"><strong>Ihr Klavier ist bei uns, als zertifizierter und offizieller Fachbetrieb für Klavier- und Cembalobau, in besten Händen!</strong></p>

<p style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 26px;">
  Bitte schauen Sie sich um und zögern Sie nicht, mich zu kontaktieren, wenn Sie noch Fragen haben. Ich stehe Ihnen gerne zur Verfügung und freue mich darauf, Ihren Flügel oder Ihr Klavier zum Klingen zu bringen und Ihnen ein musikalisches Erlebnis der Extraklasse zu ermöglichen.</p>
</div>
</div>

<div class="overroll">
<div class="zweier">
	<h1 style="font-family: 'Sienna-Bold', serif;"><span class="n-alternate">N</span>ehmen <span class="s-alternate">S</span>ie <span class="k-alternate">K</span>ontakt <span class="a-alternate">A</span>UF und <span class="l-alternate">l</span>assen Sie Ihr <span class="k-alternate">K</span>lavier <span class="s-alternate">S</span>ichten</h1>
</div>
<div style="width: 30%; float: left; margin-left: 6%; margin-right: 4%; margin-top: 28px;">
<div style="width: 20%; float: left;"><img src="../assets/hoerer-g.png" style="width:60px;"></div>
<div style="width: 100%;"><span style="font-family: 'Orto-Bold', sans-serif; font-size: 26px; line-height:38px; color: #000;">Telefon</span><br>
<span style="font-family: 'Orto-Medium', sans-serif; font-size: 26px; line-height:38px; color: #000;">+49 (0)170 - 4131 710</span></div>
</div>
<div style="width: 28%; float: left; margin-right: 2%; margin-top: 28px;">
<div style="width: 20%; float: left;"><img src="../assets/formular-g.png" style="width:60px;"></div>
<div style="width: 100%;"><a style="text-decoration: none; color: #666666;" href="#/kontakt"><span style="font-family: 'Orto-Bold', sans-serif; font-size: 26px; line-height:38px; color: #000;">Kontaktformular</span><br>
<span style="font-family: 'Orto-Medium', sans-serif; font-size: 26px; line-height:38px; color: #000;">Termin anbieten</span></a></div>
</div>
<div style="width: 28%; float: left; margin-right: 0%; margin-top: 28px;">
<div style="width: 20%; float: left;"><img src="../assets/at-g.png" style="width:60px;"></div>
<div style="width: 100%;"><span style="font-family: 'Orto-Bold', sans-serif; font-size: 26px; line-height:38px; color: #000;">E-Mail</span><br>
<span style="font-weight: bold; font-size: 14px;"><a style="font-family: 'Orto-Medium', sans-serif; font-size: 19px; line-height: 38px; display: inline; color: #000;" href="mailto:info@klavierstimmen-braunschweig.de">klavierstimmen@braunschweig</a></span></div>
</div>
</div>

  </div>
  </default>
  </template>

<script>
export default {
  data() {
    return {
      ref: null
    };
  },
  methods: {
    handleClick() {
      this.$refs.targetRef.scrollIntoView({ behavior: 'smooth' });
    }
  }
};
</script>

<style>
.home {
  background-image: url('/Users/test/Documents/Arne%20Ass/Bilder/start_01.jpg');
  height: 85vH;
  background-size: cover;
  background-position: center;
  margin: 0 auto;
  display: grid;
  /*
  margin-left: calc((100% - 1440px) / 2); /* Berechnung des Überschusses über die 1440px-Seitenbreite */
  /* margin-right: calc((100% - 1440px) / 2); /* Berechnung des Überschusses über die 1440px-Seitenbreite */
}
.cnthero {
  width: 100%;
  max-width: 1440px;
  display: inline-grid;
  grid-template-rows: auto auto auto;
  align-content: center;
  margin: 0 auto;
}
.heroslogan {
  display: inline-grid;
  align-self: center;
  margin: 0 auto;
}
h1.herohone {
  font-family: "Sienna-Bold";
  font-size: 8em;
  color: #fff;
  line-height: 1.1em;
  margin: 0;
  text-align: center;
}
a.herolink {
  font-family: 'Orto-SemiBold';
  text-transform: uppercase;
  font-size: 1.0em !important;
  letter-spacing: 0.01em;
  line-height: 54px;
  color: #fff;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  padding-left: 0px;
  padding-right: 0.2em;
  padding-top: 0.18em;
}
.subhead {
  background-image: url(../assets/subline.png);
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  text-align: center;
  margin-top: 1%;
  color: #fff;
  margin-left: auto;
  margin-right: auto;
}
h1.herohtwo {
  font-family: 'Orto-SemiBold';
  text-transform: uppercase;
  font-size: 2.7em !important;
  letter-spacing: 0.01em;
  line-height: 54px;
  color: #fff;
  width: fit-content;
  margin: auto;
  padding-left: 84px;
  padding-right: 0.2em;
  padding-top: 0.18em;
}
.button-cnt {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-items: center;
}
button {
  width: auto;
}
#allCars {
display: grid;
grid-template-columns: 0.8fr 1.2fr;
grid-gap: 1em;
margin: 0 0 2em 0;
grid-gap: 4em;
}
.foursquare {
  background-color: #32a852;
  opacity: 0.9;
  width: fit-content;
  height: fit-content;
  padding: 1.6em 3em;
  margin: -5em 0em 0em 0em;
}
.firstimg {
  background-image: url(../assets/leistungen_2.jpg);
}
.secondimg {
  background-image: url(../assets/leistungen_3.jpg);
}
.thirdimg {
  background-image: url(../assets/home_2.jpg);
}
.overroll {
  background-image: url(../assets/notensystem.png);
  min-height: 200px;
  background-attachment: fixed;
  background-position-x: center;
  background-position-y: 75%;
  background-repeat: no-repeat;
  scroll-snap-align: start;
  margin: 8em 0 4em 0;
  position: relative;
}
.zweier h1 {
  font-size: 38px;
  text-align: center;
  padding-top: 1.0em;
  margin-bottom: 0.5em;
  font-weight: bold;
  text-transform: uppercase;
  color: #000;
}
.zweier h1::after {
  content: "";
  position: absolute;
  width: 1440px;
  height: 1px;
  left: 1%;
  border-top: 2px solid #000;
  margin: 1.6em auto 0;
}
.n-alternate {
  font-feature-settings: "ss01";
}
.s-alternate {
  font-feature-settings: "ss01";
}
.k-alternate {
  font-feature-settings: "ss02";
}
.a-alternate {
  font-feature-settings: "ss03";
}
.l-alternate {
  font-feature-settings: "ss01";
}
.k-alternate {
  font-feature-settings: "ss02";
}
.s-alternate {
  font-feature-settings: "ss01";
}
/*  MEDIA SCREEN IExplorer */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) 
{}

@media (min-width: 240px) and (max-width: 384px) {
  #nav{
  display: none !important;  
  }
  #allCars {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1em;
  margin: 0 0 2em 0;
  grid-gap: 4em;
}
}

@media (min-width: 385px) and (max-width: 480px) {
}

@media (min-width: 481px) and (max-width: 640px) {
}

@media (min-width: 801px) and (max-width: 824px) {
}

@media (min-width: 825px) and (max-width: 842px) {
}

@media (min-width: 843px) and (max-width: 1024px) {
}

@media (min-width: 1025px) and (max-width: 1440px) {
}

@media (min-width: 1441px) and (max-width: 1600px) {
}

@media (min-width: 1601px) and (max-width: 2048px) {
}

@media (min-width: 2049px) and (max-width: 2560px) {
}
</style>
