<template>
   <default>
  <div class="portrait">
    <div class="cnthero">
    <div class="hslogan-leist">
      <h1 class="heroleist">Die Qualität<br>des Tons</h1>
    </div>
    <div class="subhead">
      <h1 class="herohtwo"><a class="herolink" href="/leistungen">Klavierstimmen</a></h1>
      <img src="../assets/arrow_x.png" style="align-self: center;"/>
    </div>
   <div id="scroll" style="margin: 0 auto;">
      <a @click="scrollToElement" style="position: absolute; bottom: 2em;"><img src="../assets/arrow.png" /></a>
  </div>
</div>
</div>
   <div class="main-kontent" style="width: 1440px; margin:0 auto">
    <div id="leistCont" ref="targetRef">
    <div style="margin: 4em 2em;">
    <h1 style="text-align: left; font-size: 28px; font-family: 'Sienna-Bold', sans-serif; margin: 0; line-height: 42px;">Über mich</h1>
<h2 style="text-align: left; font-size: 21px; font-family: 'Orto-Bold', sans-serif; margin: 0; line-height: 30px;">Die faszinierende Technik in Kombination mit der herrlichen Musik weckten meine Begeisterung schon in jungen Jahren</h2>
<p style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; color: #000; line-height: 26px;">Schon früh habe ich begonnen, mich für Klaviere und Flügel zu interessieren.
  Meine Eltern erfreuten sich gleichermaßen an dieser Vorliebe und ermöglichten mir, bei hochkarätigen Pianisten Unterricht zu nehmen.
  Einer von ihnen ist Marciej Lukaszczyk, Mitbegründer und Präsident der Chopin-Gesellschaft in der Bundesrepublik Deutschland e.V. mit Sitz in Darmstadt.
  Später entwickelte ich ein Faible für Reisen durch Deutschland und Europa. Mal mit dem Fahrrad in Norwegen, mal mit der Bahn in Spanien, England oder Frankreich nutzte ich die großen Ferien, um Neues zu entdecken und unseren Kontinent kennenzulernen.
  </p>
  <p style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; color: #000; line-height: 26px;">Nach der Schule war es das Studium der Physik, über das ich mich wissenschaftlich mit dem Thema Akustik auseinandersetzte und mich zum Klavierstimmer qualifizierte.
  Mein Abschluss in Verbindung mit entsprechenden Zusatzausbildungen mündeten schließlich in der Eintragung meines Fachbetriebes für Klavier- und Cembalobau bei der Handwerkskammer, mit dem ich heute mit großer Leidenschaft</p>
    </div>

    <div class="foursquare">
  <div style="display: flex;">
    <div style="  width: 50%; display: flex; margin-right: 12%;">
    <img src="../assets/icon-fon.png" style="height: fit-content;"><p style="font-family: 'Orto-Bold'; line-height: 42px; color: #fff; font-size: 28px; margin: 0px 0 0 12px;">0171-4131710</p>
  </div>
  <div style="width: 38%; display: flex;">
    <img src="../assets/icon-envalope.png" style="height: fit-content;"><p style="font-family: 'Orto-Bold'; line-height: 42px; color: #fff; font-size: 28px; margin: 0px 0 0 12px;">KONTAKT</p>
  </div>
  </div>
<div style="text-align: left; margin-top: 4em;">
<img src="../assets/rede.png" />
</div>
  <div>
  <p style="font-family: 'Sienna-Bold', sans-serif; font-size: 36px; line-height: 52px; text-align: right; font-weight: 600; color: #fff; margin: 0;">Da wo die menschliche Sprache aufhört, fängt
die Musik an.</p>
<p style="font-family: 'Orto-Bold', sans-serif; font-size: 21px; line-height: 32px; text-align: right; font-weight: 600; color: #fff; text-align: right; margin: 0;">E.T.A. Hoffmann</p>
</div>
    </div>

  </div>
  <div style="display: grid; grid-template-columns: 1.0fr 1.5fr; margin: 8em 0 0 0; grid-gap: 6em;">
<div class="betrieb" style="display: inline-grid; grid-template-rows: 0.5fr 1.5fr; position: relative;">
  <div style="display: inline-grid; justify-items: end;">
  <img src="../assets/icon-nkey.png" style="height: 5em;"/>
</div>
<div>
<h2 style="font-family: 'Sienna-Bold', sans-serif; color:#2c3e50; font-size: 36px; line-height: 50px; width: auto; letter-spacing: 0.05em; text-align: right;">Instrumentenkenntnisse<br>&<br>Referenzen</h2>
</div>
</div>
<div style="display: grid; grid-template-rows: auto 1fr;">
<div style="display: inline-grid; align-self: center;">
<h2 style="text-align: left; font-size: 24px; font-family: 'Orto-Bold', sans-serif; margin: 0; line-height: 32px;">Der Handwerksbetrieb</h2>
<p style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 26px;">Unser Betrieb ist bei der Handwerkskammer Rhein- Main eingetragen. Unsere Kunden kommen aus allen Regionen Deutschlands.
  Dabei sind nicht nur unsere privaten Kunden mit unseren Leistungen zufrieden, son- dern auch professionelle Anwender kommen immer wieder auf uns zurück.
</p>
<p style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 26px;">Unser Betrieb ist bei der Handwerkskammer Rhein- Main eingetragen. Unsere Kunden kommen aus allen Regionen Deutschlands.
  Dabei sind nicht nur unsere privaten Kunden mit unseren Leistungen zufrieden, son- dern auch professionelle Anwender kommen immer wieder auf uns zurück. Unser Betrieb ist bei der Hand- werkskammer Rhein- Main eingetragen. Unsere Kunden kommen aus allen Regionen Deutschlands. Dabei sind nicht nur unsere privaten Kunden mit unseren Leistungen zufrieden, sondern auch professionelle An- wender kommen immer wieder auf uns zurück.
</p>
<p style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 26px;">Unser Betrieb ist bei der Handwerkskammer Rhein- Main eingetragen. Unsere Kunden kommen aus allen Regionen Deutschlands.
  Dabei sind nicht nur unsere privaten Kunden mit unseren Leistungen zufrieden, son- dern auch professionelle Anwender kommen immer wieder auf uns zurück.</p>
</div>
</div>
  </div>
  <div style="display: grid; grid-template-columns: 1.5fr 1.0fr; margin: 6em 0 0 0; grid-gap: 4em;">
<div class="secondimg" style="display: inline-grid; background-size: auto 620px; background-repeat: no-repeat; height: 520px; position:relative; background-position: right top;">
<img src="../assets/tiles-r.png" style="position: absolute; height: 100%;">
<p style="font-family: 'Sienna-Medium', sans-serif; color:#fff; transform: rotate(270deg); font-size: 28px; margin: 1.2em 0px 0px -2.4em; line-height: 28px; width: auto; position: absolute; top: 80px; letter-spacing: 0.05em; position: absolute; text-transform: uppercase;">Referenzen</p>
<img src="../assets/klaviatur.png" style="bottom: 14px; position: absolute; opacity: 0.8;">
</div>
<div style="display: grid; grid-template-rows: auto 1fr;">
<div style="display: inline-grid; align-self: center;">
<h2 style="text-align: left; font-size: 21px; font-family: 'Orto-Bold', sans-serif; margin: 0; line-height: 32px;">Chopingesellschaft</h2>
<li style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 30px;">> Europäischer Chopinwettbewerb</li>
<li style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 30px;">> Konzerte</li>
<li style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 30px;">> Meisterkurse</li>
<li style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 30px;">> Chopin Gesellschaft Langjähriges Engagement</li>
</div>
<div style="display: inline-grid; align-self: center;">
<h2 style="text-align: left; font-size: 21px; font-family: 'Orto-Bold', sans-serif; margin: 0; line-height: 32px;">Musikschulen</h2>
<p style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 26px;">Überall im Rhein-Main-Gebiet kann der Unterricht bei Ihnen zu Hause oder in den Räumen der Musikschule erfolgen. Musikschule Riedstadt (Landkreis Gros-Gerau) Kleinkunstbühne Büttelborn im Café Extra
Musikschule Ammerbuch (Landkreis Tübingen)</p>
</div>
</div>
  </div>
<div class="wellen">
  <div style="display: grid; grid-template-columns: 1fr 1fr; margin: 12em 0 0 0; grid-gap: 4em;">
    <div style="display: grid; grid-template-rows: auto auto 1fr;">
      <h2 style="text-align: left; font-size: 24px; font-family: 'Sienna-Bold', sans-serif; margin: 0; line-height: 38px;">Wie man die Stimmtonhöhe prüft</h2>
<div style="display: inline-grid; align-self: center;">
<h2 style="text-align: left; font-size: 21px; font-family: 'Orto-Bold', sans-serif; margin: 0; line-height: 32px;">Die Qualität des Tones</h2>
<p style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 26px;">
Beim Stimmen von einem Klavier ändert der Klavierstimmer die Qualität des Tones, den das Klavier beim Spiel erzeugt. Das ist ein wichtiger Bestandteil der Handhabung und Pflege von einem Klavier.
Viele übersehen das häufig oder empfinden es als nicht entscheidend.Einige Klavierspieler und Klavierbesitzer gehen davon aus, dass ein Klavier so klingen soll, wie es aktuell klingt.
Leider entspricht das nicht immer der Wahrheit und genau da kommt der Stimmer ins Spiel.</p>
</div>
</div>
<div style="display: grid; grid-template-rows: auto auto 1fr;">
      <h2 style="text-align: left; font-size: 24px; font-family: 'Sienna-Bold', sans-serif; margin: 0; line-height: 32px;">&nbsp;</h2>
      <div style="display: inline-grid; align-self: center;">
      <h2 style="text-align: left; font-size: 21px; font-family: 'Orto-Bold', sans-serif; margin: 0; line-height: 32px;">Das geschulte Gehör</h2>
  <p style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 26px;">
    Er hat ein geschultes Gehör für die Zwischenklänge, die ein normaler Mensch überhören könnte. Er benutzt zusätzlich noch eine Stimmgabel oder ein Stimmgerät und arbeitet außerdem mit einem Stimmhammer.
    Im Wesentlichen ist das Klavier stimmen wie ein mathematisches Problem. Die Klaviernoten stimmen entwe- der oder sie stimmen eben nicht.</p>
</div>
</div>
  </div>
   
  <div style="display: grid; grid-template-columns: 1.5fr 1fr; margin: 6em 0 0 0; grid-gap: 4em;">
<div>
<h2 style="text-align: left; font-size: 21px; font-family: 'Orto-Bold', sans-serif; margin: 0; line-height: 32px;">Was überprüft der Klavierstimmer?</h2>
<li style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 30px;">> das Klavier wird auf die Stimmtonhöhe überprüft
</li>
  <li style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 30px;">> Unterschiede der Tonhöhe elektronisch mit Stimmgabeln oder per Gehör feststellen
</li>
<li style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 30px;">> Klangart der Töne anzupassen
  </li>
  <li style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 30px;">> die Klaviere durch differierende Spannungen der Saiten mit dem Stimmhammer stimmen
</li>
<li style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 30px;">> Klavier-Tasten regulieren,
  </li>
  <li style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 30px;">> das Klavier auf Fehler prüfen,
  </li>
  <li style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 30px;">> das Innere des Klaviers reinigen, eventuelle Mängel herausfinden und beheben,
  </li>
  <li style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 30px;">> Klaviere von professionellen Spielern vor Konzerten stimmen,
  </li>
  <li style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 30px;">> Kunden über die beste Pflege beraten, mit inbegriffen Tipps zum Standort des Klaviers und zu Reinigungsmitteln,
  </li> 
  <li style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 30px;">> kleinere Reparaturen an Klavieren vornehmen, zum Beispiel eingeklemmte Klavier Tasten lösen sowie gerissene Saiten oder Hammerköpfe ersetzen.
  </li>
</div>
<div>
      <img src="../assets/stimmen.png" style="width: 100%; filter: grayscale(100%);" />
</div>
  </div>
</div>
<div style="display: grid; grid-template-columns: 1.0fr 1.5fr; margin: 12em 0 0 0; grid-gap: 6em;">
<div class="betrieb" style="display: inline-grid; grid-template-rows: 0.5fr 1.5fr; position: relative;">
  <div style="display: inline-grid; justify-items: end;">
  <img src="../assets/icon-nkey.png" style="height: 6em;"/>
</div>
<div>
<h2 style="font-family: 'Sienna-Bold', sans-serif; color:#2c3e50; font-size: 36px; line-height: 50px; width: auto; letter-spacing: 0.05em; text-align: right;">Woher weiß ich, ob mein Klavier gestimmt werden muss?</h2>
</div>
</div>
<div style="display: grid; grid-template-rows: auto 1fr;">
<div style="display: inline-grid; align-self: center;">
<h2 style="text-align: left; font-size: 24px; font-family: 'Orto-Bold', sans-serif; margin: 0; line-height: 32px;">Es gibt ein paar Grundsätze und Richtlinien um zu prüfen, ob dein Klavier gestimmt werden muss:</h2>
<p style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 26px;">Bevor du dich dazu entscheidest dein Klavier stimmen zu lassen, solltest du ein paar Lieder begleiten oder Melodien auf deinem Klavier spielen, um herauszufinden, ob irgendetwas schräg klingt.
</p>
<p style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 26px;">Die folgenden Aspekte einen Hinweis auf Abhilfe geben:
Dein Klavier klingt anders, als es beim Kauf geklungen hat.
Dir gefällt der Ton von deinem Klavier nicht, obwohl es bereits gestimmt wurde. Der Ton ändert sich von Note zu Note.
Du kannst auf deinem Klavier keine leisen Töne und Melodien spielen.</p>
</div>
</div>
  </div>

<div class="overroll">
<div class="zweier">
	<h1 style="font-family: 'Sienna-Bold', serif;"><span class="n-alternate">N</span>ehmen <span class="s-alternate">S</span>ie <span class="k-alternate">K</span>ontakt <span class="a-alternate">A</span>UF und <span class="l-alternate">l</span>assen Sie Ihr <span class="k-alternate">K</span>lavier <span class="s-alternate">S</span>ichten</h1>
</div>
<div style="width: 30%; float: left; margin-left: 6%; margin-right: 4%; margin-top: 28px;">
<div style="width: 20%; float: left;"><img src="../assets/hoerer-g.png" style="width:60px;"></div>
<div style="width: 100%;"><span style="font-family: 'Orto-Bold', sans-serif; font-size: 26px; line-height:38px; color: #000;">Telefon</span><br>
<span style="font-family: 'Orto-Medium', sans-serif; font-size: 26px; line-height:38px; color: #000;">+49 (0)170 - 4131 710</span></div>
</div>
<div style="width: 28%; float: left; margin-right: 2%; margin-top: 28px;">
<div style="width: 20%; float: left;"><img src="../assets/formular-g.png" style="width:60px;"></div>
<div style="width: 100%;"><a style="text-decoration: none; color: #666666;" href="#/kontakt"><span style="font-family: 'Orto-Bold', sans-serif; font-size: 26px; line-height:38px; color: #000;">Kontaktformular</span><br>
<span style="font-family: 'Orto-Medium', sans-serif; font-size: 26px; line-height:38px; color: #000;">Termin anbieten</span></a></div>
</div>
<div style="width: 28%; float: left; margin-right: 0%; margin-top: 28px;">
<div style="width: 20%; float: left;"><img src="../assets/at-g.png" style="width:60px;"></div>
<div style="width: 100%;"><span style="font-family: 'Orto-Bold', sans-serif; font-size: 26px; line-height:38px; color: #000;">E-Mail</span><br>
<span style="font-weight: bold; font-size: 14px;"><a style="font-family: 'Orto-Medium', sans-serif; font-size: 19px; line-height: 38px; display: inline; color: #000;" href="mailto:info@klavierstimmen-braunschweig.de">klavierstimmen@braunschweig</a></span></div>
</div>
</div>

  </div>
  </default>
</template>

<script>
export default {
  methods: {
    scrollToElement() {
      const element = document.getElementById('leistCont');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }
}
</script>

<style>
.portrait {
  background-image: url('../assets/portrait.jpg');
  height: 85vH;
  background-size: cover;
  background-position: center;
  margin: 0 auto;
  display: grid;
  /*
  margin-left: calc((100% - 1440px) / 2); /* Berechnung des Überschusses über die 1440px-Seitenbreite */
  /* margin-right: calc((100% - 1440px) / 2); /* Berechnung des Überschusses über die 1440px-Seitenbreite */
}
.hslogan-leist {
  display: inline-grid;
  align-self: center;
  margin: 0 auto;
}
h1.heroleist {
  font-family: "Sienna-Bold";
  font-size: 8em;
  color: #fff;
  line-height: 1.1em;
  margin: 0;
  text-align: center;
}
#leistCont {
  display: grid;
  grid-template-columns: 1.2fr 0.8fr;
  grid-gap: 1em;
  margin: 0 0 2em 0;
  grid-gap: 4em;
}
.wellen {
  background-image: url(../assets/wellen_trans.png);
  background-repeat: no-repeat;
  background-position: 10px 50px;
}
</style>