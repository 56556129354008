<template>
    <default>
   <div class="piano">
     <div class="cnthero">
     <div class="hslogan-leist">
       <h1 class="heroleist">Regulieren<br>Ihres Klaviers</h1>
     </div>
     <div class="subhead">
       <h1 class="herohtwo"><a class="herolink" href="/leistungen">Klavierstimmen</a></h1>
       <img src="../assets/arrow_x.png" style="align-self: center;"/>
     </div>
    <div id="scroll" style="margin: 0 auto;">
       <a @click="scrollToElement" style="position: absolute; bottom: 2em;"><img src="../assets/arrow.png" /></a>
   </div>
 </div>
 </div>
    <div class="main-kontent">
     <div id="leistCont" ref="targetRef">
     <div style="margin: 4em 2em;">
     <h1 style="text-align: left; font-size: 28px; font-family: 'Sienna-Bold', sans-serif; margin: 0; line-height: 42px;">Das Klavier und seine Vorlieben</h1>
 <h2 style="text-align: left; font-size: 21px; font-family: 'Orto-Bold', sans-serif; margin: 0; line-height: 30px;"></h2>
 <p style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; color: #000; line-height: 26px;">
    Das Klavier wurde Ende des 17. Jahrhunderts erfunden.</p><p style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; color: #000; line-height: 26px;">Es ist ein Tasteninstrument, bei dem über eine Mechanik Hämmerchen gegen eine Seite geschleudert werden (wie bei einem Schlaginstrument).
    Je Ton werden 2 – 3 Saiten angeschlagen: auch Saitenchor genannt.
   </p>
     </div>
 
     <div class="foursquare">
   <div style="display: flex;">
     <div style="  width: 50%; display: flex; margin-right: 12%;">
     <img src="../assets/icon-fon.png" style="height: fit-content;"><p style="font-family: 'Orto-Bold'; line-height: 42px; color: #fff; font-size: 28px; margin: 0px 0 0 12px;">0171-4131710</p>
   </div>
   <div style="width: 38%; display: flex;">
     <img src="../assets/icon-envalope.png" style="height: fit-content;"><p style="font-family: 'Orto-Bold'; line-height: 42px; color: #fff; font-size: 28px; margin: 0px 0 0 12px;">KONTAKT</p>
   </div>
   </div>
 <div style="text-align: left; margin-top: 4em;">
 <img src="../assets/rede.png" />
 </div>
   <div>
   <p style="font-family: 'Sienna-Bold', sans-serif; font-size: 36px; line-height: 52px; text-align: right; font-weight: 600; color: #fff; margin: 0;">Da wo die menschliche Sprache aufhört, fängt
 die Musik an.</p>
 <p style="font-family: 'Orto-Bold', sans-serif; font-size: 21px; line-height: 32px; text-align: right; font-weight: 600; color: #fff; text-align: right; margin: 0;">E.T.A. Hoffmann</p>
 </div>
     </div>
 
   </div>
   <div class="text-box">
 <div class="betrieb">
   <div class="topic-icon">
   <img src="../assets/icon-nkey.png" style="height: 5em;"/>
 </div>
 <div>
 <h2 class="topic-hline">	
Bausteine<br>&<br>
Eigenschaften</h2>
 </div>
 </div>
 <div style="display: grid; grid-template-rows: auto 1fr;">
 <div style="display: inline-grid; align-self: center;">
 <h2 style="text-align: left; font-size: 24px; font-family: 'Orto-Bold', sans-serif; margin: 0; line-height: 32px;">Historie</h2>
 <p style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 26px;">Verbreitet sind die Formen: Piano / Klavier (upright piano) und Flügel (grand piano).
Klavier kommt von claves (Tasten, eigentlich Schlüssel) im französischen; Piano hat sich als Kurzform von piano (leise) und forte (laut) gegenüber Pianoforte durchgesetzt.</p>
 <p style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 26px;">Das Klavier hat 88 Tasten, 52 weiße und 36 schwarze; eine Oktave umfasst 7 weiße und 5 schwarze Tasten. Es hat eine Breite von 123 cm Breite (Klavier), wenn es genormt ist.
    Die Instrumente haben 2 oder 3 Pedale: das piano-Pedal und das Forte-Pedal (Aufhebung der Dämpfung); die Mitte kann als Tonhaltepedal (nur die Dämpfung der angespielten Töne wird aufgehoben) oder Moderatorpedal (alle Saiten zusätzlich per Filz gedämpft) ausgelegt sein. Gewicht: kleine Flügel wiegen um die 300 kg, große Konzertflügel bis zu 700 kg; normale Klaviere zwischen 200 kg und 300 kg.
Verbreitung</p>
<p style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 26px;">Im Jahr 1980 gab es in den bundesdeutschen Privathaushalten etwa 9.300.000 Flöten, 8.400.000 Mundharmonikas/Melodikas, 3.800.000 Gitarren, 2.200.000 Akkordeons und 1.600.000 Pianinos/Flügel.
    (Quelle: bei wikiped nachschlagen)
</p>
 </div>
 </div>
   </div>
   <div style="display: grid; grid-template-columns: 1.5fr 1.0fr; margin: 6em 0 0 0; grid-gap: 4em;">
 <div class="pianoimg" style="display: inline-grid; background-size: auto 620px; background-repeat: no-repeat; height: 520px; position:relative; background-position: right top;">
 <img src="../assets/tiles-r.png" style="position: absolute; height: 100%;">
 <p style="font-family: 'Sienna-Medium', sans-serif; color:#fff; transform: rotate(270deg); font-size: 28px; margin: 1.2em 0px 0px -2.4em; line-height: 28px; width: auto; position: absolute; top: 80px; letter-spacing: 0.05em; position: absolute; text-transform: uppercase;">Referenzen</p>
 <img src="../assets/klaviatur.png" style="bottom: 14px; position: absolute; opacity: 0.8;">
 </div>
 <div style="display: grid; grid-template-rows: auto 1fr;">
 <div style="display: inline-grid; align-self: center;">
 <h2 style="text-align: left; font-size: 21px; font-family: 'Orto-Bold', sans-serif; margin: 0; line-height: 32px;">Chopingesellschaft</h2>
 <li style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 30px;">> Europäischer Chopinwettbewerb</li>
 <li style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 30px;">> Konzerte</li>
 <li style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 30px;">> Meisterkurse</li>
 <li style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 30px;">> Chopin Gesellschaft Langjähriges Engagement</li>
 </div>
 <div style="display: inline-grid; align-self: center;">
 <h2 style="text-align: left; font-size: 21px; font-family: 'Orto-Bold', sans-serif; margin: 0; line-height: 32px;">Musikschulen</h2>
 <p style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 26px;">Überall im Rhein-Main-Gebiet kann der Unterricht bei Ihnen zu Hause oder in den Räumen der Musikschule erfolgen. Musikschule Riedstadt (Landkreis Gros-Gerau) Kleinkunstbühne Büttelborn im Café Extra
 Musikschule Ammerbuch (Landkreis Tübingen)</p>
 </div>
 </div>
   </div>
 <div class="wellen">
   <div style="display: grid; grid-template-columns: 1fr 1fr; margin: 12em 0 0 0; grid-gap: 4em;">
     <div style="display: grid; grid-template-rows: auto auto 1fr;">
       <h2 style="text-align: left; font-size: 24px; font-family: 'Sienna-Bold', sans-serif; margin: 0; line-height: 36px;">Wie man die Stimmtonhöhe prüft</h2>
 <div style="display: inline-grid; align-self: center;">
 <h2 style="text-align: left; font-size: 21px; font-family: 'Orto-Bold', sans-serif; margin: 0; line-height: 32px;">Die Qualität des Tones</h2>
 <p style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 26px;">
 Beim Stimmen von einem Klavier ändert der Klavierstimmer die Qualität des Tones, den das Klavier beim Spiel erzeugt. Das ist ein wichtiger Bestandteil der Handhabung und Pflege von einem Klavier.
 Viele übersehen das häufig oder empfinden es als nicht entscheidend.Einige Klavierspieler und Klavierbesitzer gehen davon aus, dass ein Klavier so klingen soll, wie es aktuell klingt.
 Leider entspricht das nicht immer der Wahrheit und genau da kommt der Stimmer ins Spiel.</p>
 </div>
 </div>
 <div style="display: grid; grid-template-rows: auto auto 1fr;">
       <h2 style="text-align: left; font-size: 24px; font-family: 'Sienna-Bold', sans-serif; margin: 0; line-height: 32px;">&nbsp;</h2>
       <div style="display: inline-grid; align-self: center;">
       <h2 style="text-align: left; font-size: 21px; font-family: 'Orto-Bold', sans-serif; margin: 0; line-height: 32px;">Das geschulte Gehör</h2>
   <p style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 26px;">
     Er hat ein geschultes Gehör für die Zwischenklänge, die ein normaler Mensch überhören könnte. Er benutzt zusätzlich noch eine Stimmgabel oder ein Stimmgerät und arbeitet außerdem mit einem Stimmhammer.
     Im Wesentlichen ist das Klavier stimmen wie ein mathematisches Problem. Die Klaviernoten stimmen entwe- der oder sie stimmen eben nicht.</p>
 </div>
 </div>
   </div>
    
   <div style="display: grid; grid-template-columns: 1.5fr 1fr; margin: 6em 0 0 0; grid-gap: 4em;">
 <div>
 <h2 style="text-align: left; font-size: 21px; font-family: 'Orto-Bold', sans-serif; margin: 0; line-height: 32px;">Was überprüft der Klavierstimmer?</h2>
 <li style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 30px;">> das Klavier wird auf die Stimmtonhöhe überprüft
 </li>
   <li style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 30px;">> Unterschiede der Tonhöhe elektronisch mit Stimmgabeln oder per Gehör feststellen
 </li>
 <li style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 30px;">> Klangart der Töne anzupassen
   </li>
   <li style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 30px;">> die Klaviere durch differierende Spannungen der Saiten mit dem Stimmhammer stimmen
 </li>
 <li style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 30px;">> Klavier-Tasten regulieren,
   </li>
   <li style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 30px;">> das Klavier auf Fehler prüfen,
   </li>
   <li style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 30px;">> das Innere des Klaviers reinigen, eventuelle Mängel herausfinden und beheben,
   </li>
   <li style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 30px;">> Klaviere von professionellen Spielern vor Konzerten stimmen,
   </li>
   <li style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 30px;">> Kunden über die beste Pflege beraten, mit inbegriffen Tipps zum Standort des Klaviers und zu Reinigungsmitteln,
   </li> 
   <li style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 30px;">> kleinere Reparaturen an Klavieren vornehmen, zum Beispiel eingeklemmte Klavier Tasten lösen sowie gerissene Saiten oder Hammerköpfe ersetzen.
   </li>
 </div>
 <div>
       <img src="../assets/stimmen.png" style="width: 100%; filter: grayscale(100%);" />
 </div>
   </div>
 </div>
 <div style="display: grid; grid-template-columns: 1.0fr 1.5fr; margin: 12em 0 0 0; grid-gap: 6em;">
 <div class="betrieb" style="display: inline-grid; grid-template-rows: 0.5fr 1.5fr; position: relative;">
   <div style="display: inline-grid; justify-items: end;">
   <img src="../assets/icon-nkey.png" style="height: 6em;"/>
 </div>
 <div>
 <h2 style="font-family: 'Sienna-Bold', sans-serif; color:#2c3e50; font-size: 36px; line-height: 50px; width: auto; letter-spacing: 0.05em; text-align: right;">	
Moderne<br>&<br>
Zeiten
</h2>
 </div>
 </div>
 <div style="display: grid; grid-template-rows: auto 1fr;">
 <div style="display: inline-grid; align-self: center;">
 <h2 style="text-align: left; font-size: 24px; font-family: 'Orto-Bold', sans-serif; margin: 0; line-height: 32px;">Kosten</h2>
 <p style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 26px;">Klaviere: gebraucht in mittlerer Qualität und Zustand kosten zwischen € 1.000,- und € 3.000,-; neue ab € 5.000,-.
    Flügel: sind gebraucht unter € 3.000,- kaum zu bekommen, eine größere Auswahl gibt es ab € 10.000,- und neu kosten sie zwischen € 20.000,- und € 100.000,-. Unterhalt: eine Stimmung kostet ca. € 100,- und sollte ein bis zwei Mal im Jahr erfolgen.
 </p>
 <h2 style="text-align: left; font-size: 24px; font-family: 'Orto-Bold', sans-serif; margin: 0; line-height: 32px;">Pflege und Vorlieben</h2>
 <p style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 26px;">Das Raumklima ist wichtig: zu wenig Luftfeuchtigkeit führt zur Austrocknung, zu viel führt zu Rost. Der Aufstellort ist wichtig: wenig Temperaturschwankungen, wenig Zug, wenig Feuchtigkeitsschwankungen, nicht zu dicht an Heizung oder Ofen mag das Klavier am liebsten.
    In Asien werden z.B. spezielle Export-Klaviere produziert, die an die Klimaverhältnisse im Zielland angepasst sind.</p>
    <p style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 26px;">Seit Ende des 20. Jahrhunderts werden auch Digitalklaviere mit echten Klaviermechaniken hergestellt.
        Der Ton wird nicht mehr synthetisiert, sondern es werden digitalisierte Aufnahmen hochwertiger Instrumente abgespielt und erstaunliche Klangergebnisse bei Digitalklavieren erzielt.</p>
 </div>
 </div>
   </div>
 
 <div class="overroll">
 <div class="zweier">
     <h1 style="font-family: 'Sienna-Bold', serif;"><span class="n-alternate">N</span>ehmen <span class="s-alternate">S</span>ie <span class="k-alternate">K</span>ontakt <span class="a-alternate">A</span>UF und <span class="l-alternate">l</span>assen Sie Ihr <span class="k-alternate">K</span>lavier <span class="s-alternate">S</span>ichten</h1>
 </div>
 <div style="width: 30%; float: left; margin-left: 6%; margin-right: 4%; margin-top: 28px;">
 <div style="width: 20%; float: left;"><img src="../assets/hoerer-g.png" style="width:60px;"></div>
 <div style="width: 100%;"><span style="font-family: 'Orto-Bold', sans-serif; font-size: 26px; line-height:38px; color: #000;">Telefon</span><br>
 <span style="font-family: 'Orto-Medium', sans-serif; font-size: 26px; line-height:38px; color: #000;">+49 (0)170 - 4131 710</span></div>
 </div>
 <div style="width: 28%; float: left; margin-right: 2%; margin-top: 28px;">
 <div style="width: 20%; float: left;"><img src="../assets/formular-g.png" style="width:60px;"></div>
 <div style="width: 100%;"><a style="text-decoration: none; color: #666666;" href="#/kontakt"><span style="font-family: 'Orto-Bold', sans-serif; font-size: 26px; line-height:38px; color: #000;">Kontaktformular</span><br>
 <span style="font-family: 'Orto-Medium', sans-serif; font-size: 26px; line-height:38px; color: #000;">Termin anbieten</span></a></div>
 </div>
 <div style="width: 28%; float: left; margin-right: 0%; margin-top: 28px;">
 <div style="width: 20%; float: left;"><img src="../assets/at-g.png" style="width:60px;"></div>
 <div style="width: 100%;"><span style="font-family: 'Orto-Bold', sans-serif; font-size: 26px; line-height:38px; color: #000;">E-Mail</span><br>
 <span style="font-weight: bold; font-size: 14px;"><a style="font-family: 'Orto-Medium', sans-serif; font-size: 19px; line-height: 38px; display: inline; color: #000;" href="mailto:info@klavierstimmen-braunschweig.de">klavierstimmen@braunschweig</a></span></div>
 </div>
 </div>
 
   </div>
   </default>
 </template>
 
 <script>
 export default {
   methods: {
     scrollToElement() {
       const element = document.getElementById('leistCont');
       if (element) {
         element.scrollIntoView({ behavior: 'smooth' });
       }
     }
   }
 }
 </script>
 
 <style>
 .main-kontent {
  max-width: 1440px;
  margin:0 auto;
  width: 100%;
 }
 .text-box {
  display: grid;
  grid-template-columns: 1.0fr 1.5fr;
  margin: 8em 0 0 0;
  grid-gap: 6em;
 }
 .betrieb {
  display: inline-grid;
  grid-template-rows: 0.5fr 1.5fr;
  position: relative;
 }
 .topic-icon {
  display: inline-grid;
  justify-items: end;
 }
 .topic-hline {
  font-family: 'Sienna-Bold', sans-serif;
  color:#2c3e50;
  font-size: 36px;
  line-height: 50px;
  width: auto;
  letter-spacing: 0.05em;
  text-align: right;
 }
 .piano {
   background-image: url('../assets/leistungen_1.jpg');
   height: 85vH;
   background-size: cover;
   background-position: center;
   margin: 0 auto;
   display: grid;
   /*
   margin-left: calc((100% - 1440px) / 2); /* Berechnung des Überschusses über die 1440px-Seitenbreite */
   /* margin-right: calc((100% - 1440px) / 2); /* Berechnung des Überschusses über die 1440px-Seitenbreite */
 }
 .pianoimg {
    background-image: url(../assets/home_2.jpg);
 }
 .hslogan-leist {
   display: inline-grid;
   align-self: center;
   margin: 0 auto;
 }
 h1.heroleist {
   font-family: "Sienna-Bold";
   font-size: 8em;
   color: #fff;
   line-height: 1.1em;
   margin: 0;
   text-align: center;
 }
 #leistCont {
   display: grid;
   grid-template-columns: 1.2fr 0.8fr;
   grid-gap: 1em;
   margin: 0 0 2em 0;
   grid-gap: 4em;
 }
 .wellen {
   background-image: url(../assets/wellen_trans.png);
   background-repeat: no-repeat;
   background-position: 10px 50px;
 }
 @media (min-width: 240px) and (max-width: 384px) {
  
}

@media (min-width: 385px) and (max-width: 480px) {
  
}

@media (min-width: 481px) and (max-width: 640px) {
  #leistCont {
    grid-template-columns: 1.0fr !important;
  }
  .text-box {
    grid-template-columns: 1.0fr;
  }
  .topic-icon {
  justify-items: start;
 }
 .topic-hline {
  text-align: left;
 }
}

@media (min-width: 641px) and (max-width: 824px) {
  
}

@media (min-width: 801px) and (max-width: 824px) {
  
}

@media (min-width: 825px) and (max-width: 842px) {
  
}

@media (min-width: 843px) and (max-width: 1024px) {
  
}

@media (min-width: 1025px) and (max-width: 1440px) {
  
}

@media (min-width: 1441px) and (max-width: 1600px) {
}

@media (min-width: 1601px) and (max-width: 2048px) {
}

@media (min-width: 2049px) and (max-width: 2560px) {
}
 </style>