<template>
    <header>
        <div class="masthead" style="">
    <div class="logo" style=""><a href="/" style=""><img style="" src="../assets/logo-retin.png" alt="Logo" /></a></div>
  <div id="nav">
    <li><RouterLink to="/klavier">Das Klavier</RouterLink></li><span class="seperator">|</span>
    <li><RouterLink to="/portrait">Portrait</RouterLink></li><span class="seperator">|</span>
    <li><RouterLink to="/leistungen">Leistungen</RouterLink></li><span class="seperator">|</span>
    <li><RouterLink to="/kontakt">Kontakt</RouterLink></li>
  </div>
  <div style="display: flex; justify-content: end; align-items: end; grid-gap: 1em; padding-bottom: 0.7em;">
  <Slide
    right
    enableOutsideClick
    :closeOnNavigation="true" 
    @openMenu="handleOpenMenu"
    @closeMenu="handleCloseMenu"
    isOpen
    width= "100vW !important;"
    >
      <RouterLink to="/klavier">
        <span>Das Klavier</span>
      </RouterLink>
      <RouterLink to="/portrait">
        <span>Portrait</span>
      </RouterLink>
      <RouterLink to="/leistungen">
        <span>Leistungen</span>
      </RouterLink>
      <RouterLink to="/kontakt">
        <span>Kontakt</span>
      </RouterLink>
      <RouterLink to="/impressum">
        <span>Impressum</span>
      </RouterLink>
      <RouterLink to="/datenschutz">
        <span>Datenschutz</span>
      </RouterLink>
    </Slide>
  </div>
  <router-view/>
  
  

</div>
    </header>
  </template>
  
  <script>
import { Slide } from 'vue3-burger-menu';
import 'vue3-burger-menu/dist/vue3-burger-menu.css';

  export default {
    // Component options
    name: 'Header',
    components: {
        Slide // Register your component
    },
    methods: {
    handleOpenMenu() {
      const slideMenu = document.getElementsByClassName('bm-menu')[0];
      slideMenu.style.width = '100vW'; // Setze die Breite auf 200 Pixel
    },
    handleCloseMenu() {
      // Handle the menu close event
    },
  },
}
  </script>
  
  <style>
  header {
    padding: 12px 20px 6px;
    /* Add more styles as needed */
  }
  .masthead {
    display: grid;
    grid-template-columns: 1fr 3.0fr 0.3fr;
    width: 100%;
    max-width: 1440px;
    height: 13vH;
    margin: 0 auto;
  }
  .logo {
    display: inline-grid;
    align-content: end;
  }
  .logo a {
    width: fit-content;
  }
  .logo img {
    width: fit-content;
    margin-bottom: -0.5em;
  }
  #nav a {
    width: fit-content;
    text-decoration: none;
    font-size: 24px;
    line-height: 16px;
    text-transform: uppercase;
    font-family: 'Orto-SemiBold', sans-serif;
    color:rgb(22, 22, 22);
}
#nav {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    grid-gap: 1em;
    padding-bottom: 0.3em;
}
#nav a:hover {
    color:rgb(8, 182, 86)
}
#nav a li {
  line-height:24px;
}
span.seperator {
   font-family: 'Orto';
   font-size: 21px;
}
.bm-burger-button {
  position: relative !important;
  width: 50px !important;
  height: 30px !important;
  left: 0 !important;
  top: 0 !important;
  cursor: pointer;
}
.bm-menu {
background-color: rgb(0, 0, 0) !important;
}
.bm-item-list {
margin: auto !important;
}
nav.bm-item-list a {
  margin: auto;
}
nav.bm-item-list a span {
  width: 100vW;
  text-align: center;
  font-size: 34px;
  line-height: 46px;
  text-transform: uppercase;
  font-family: 'Orto-SemiBold', sans-serif;
}
nav.bm-item-list a span:hover {
  color:#32a852;
}
.bm-burger-bars {
  background-color: #32a852 !important;
}
/*  MEDIA SCREEN IExplorer */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) 
{}

@media (min-width: 240px) and (max-width: 384px) {
  .masthead {
    display: grid;
    grid-template-columns: 1fr 0.5fr;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
  }
  .logo img {
    width: 100%;
  }
  .bm-burger-button {
  width: 40px !important;
  height: 20px !important;
}
#nav {
    display: none !important;
  }
}

@media (min-width: 385px) and (max-width: 480px) {
  #nav {
    display: none !important;
  }
}

@media (min-width: 481px) and (max-width: 640px) {
  #nav {
    display: none !important;
  }
}

@media (min-width: 641px) and (max-width: 824px) {
  #nav {
    display: none !important;
  }
}

@media (min-width: 801px) and (max-width: 824px) {
  #nav {
    display: none !important;
  }
}

@media (min-width: 825px) and (max-width: 842px) {
  #nav {
    display: none !important;
  }
}

@media (min-width: 843px) and (max-width: 1024px) {
  #nav {
    display: none !important;
  }
}

@media (min-width: 1025px) and (max-width: 1440px) {
  #nav {
    display: none !important;
  }
}

@media (min-width: 1441px) and (max-width: 1600px) {
}

@media (min-width: 1601px) and (max-width: 2048px) {
}

@media (min-width: 2049px) and (max-width: 2560px) {
}
</style>