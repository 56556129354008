<template>
  <div id="app">
      <router-view name="header"></router-view>
      <main>
          <fade-transition origin="center" mode="out-in" :duration="250">
              <router-view/>
          </fade-transition>
      </main>
      <vue-cookie-comply
      :preferences="preferences"
    @on-save-cookie-preferences="onSavePreferences"
  >
    <template v-slot:header>
      <header>Diese Website verwendet Cookies, um Ihnen das bestmögliche Online-Erlebnis zu bieten.<br>
        Durch die Nutzung unserer Website erklären Sie sich mit der Verwendung von Cookies gemäß unserer Cookie-Richtlinie einverstanden.</header>
    </template>

    <template v-slot:modal-header>
      <h3>Bitte entscheiden Sie mit welchen Cookies und welchen Diensten Sie die Daten dieser Website weiterlesen wollen.</h3>
    </template>

    <template v-slot:modal-body="{ preference, index }">
      <template v-if="index === 0">
 <h2>{{ preference.title }}</h2>
 <p>{{ preference.description }}</p>
 <div class="cookie-comply__modal-switches">
  <h3 v-for="item in preference.items" :key="item.label">
        {{ item.label }}
        </h3>
 <label v-for="item in preference.items" :key="item.label" class="cookie-comply-switch"><input :id="item.value" type="checkbox" :value="item.value" :disabled="item.isRequired" /><span class="cookie-comply-slider cookie-comply-round" :class="{'cookie-comply-required': item.isRequired}"></span></label>
</div>
</template>
    </template>

  
  </vue-cookie-comply>
      <router-view name="footer"></router-view>
  </div>
</template>
<script>
export default {
    methods: {
      onSavePreferences(preferences) {
        console.log(preferences) // ['performance', 'ga', 'newRelic']
    }
    },
    data() {
  return {
    preferences: [
      {
        title: 'Performance',
        description: 'Zur Erhebung nutzen wir Cookis',
        items: [{ label: 'Active', value: 'performance', isRequired: true }],
      },
      {
        title: 'Analytics',
        description: 'Hier sind die verschieden Dienste',
        items: [
          { label: 'GoogleAnalytics', value: 'ga' },
          { label: 'Sentry', value: 'sentry', isEnable: true },
          { label: 'Mapbox', value: 'mapbox' },
          { label: 'New Relic', value: 'newRelic', isEnable: true },
          { label: 'Dog Food', value: 'dogfood' },
        ],
      },
    ],
  };
}
  }
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 0px;
}
body {
  margin:0 ;
}
@font-face {
  font-family: "Sienna-Italic";
  src: url('assets/webFonts/SiennaItalic/font.woff2') format('woff2'),
  url('assets/webFonts/SiennaItalic/font.woff') format('woff');
}
@font-face {
  font-family: "Sienna-Black";
  src: url('assets/webFonts/SiennaBlack/font.woff2') format('woff2'),
  url('assets/webFonts/SiennaBlack/font.woff') format('woff');
}
@font-face {
  font-family: "Sienna-LightItalic";
  src: url('assets/webFonts/SiennaLightItalic/font.woff2') format('woff2'),
  url('assets/webFonts/SiennaLightItalic/font.woff') format('woff');
}
@font-face {
  font-family: "SiennaVFItalic-VariableItalic";
  src: url('assets/webFonts/SiennaVFItalicVariableItalic/font.woff2') format('woff2'),
  url('assets/webFonts/SiennaVFItalicVariableItalic/font.woff') format('woff');
}
@font-face {
  font-family: "Orto-ThinItalic";
  src: url('assets/webFonts/OrtoThinItalic/font.woff2') format('woff2'),
  url('assets/webFonts/OrtoThinItalic/font.woff') format('woff');
}
@font-face {
  font-family: "SiennaVF-Variable";
  src: url('assets/webFonts/SiennaVFVariable/font.woff2') format('woff2'),
  url('assets/webFonts/SiennaVFVariable/font.woff') format('woff');
}
@font-face {
  font-family: "Sienna-Regular";
  src: url('assets/webFonts/SiennaRegular/font.woff2') format('woff2'),
  url('assets/webFonts/SiennaRegular/font.woff') format('woff');
}
@font-face {
  font-family: "Sienna-Medium";
  src: url('assets/webFonts/SiennaMedium/font.woff2') format('woff2'),
  url('assets/webFonts/SiennaMedium/font.woff') format('woff');
}
@font-face {
  font-family: "Sienna-ThinItalic";
  src: url('assets/webFonts/SiennaThinItalic/font.woff2') format('woff2'),
  url('assets/webFonts/SiennaThinItalic/font.woff') format('woff');
}
@font-face {
  font-family: "Sienna-Thin";
  src: url('assets/webFonts/SiennaThin/font.woff2') format('woff2'),
  url('assets/webFonts/SiennaThin/font.woff') format('woff');
}
@font-face {
  font-family: "Sienna-Light";
  src: url('assets/webFonts/SiennaLight/font.woff2') format('woff2'),
  url('assets/webFonts/SiennaLight/font.woff') format('woff');
}
@font-face {
  font-family: "Sienna-BlackItalic";
  src: url('assets/webFonts/SiennaBlackItalic/font.woff2') format('woff2'),
  url('assets/webFonts/SiennaBlackItalic/font.woff') format('woff');
}
@font-face {
  font-family: "Sienna-Bold";
  src: url('assets/webFonts/SiennaBold/font.woff2') format('woff2'),
  url('assets/webFonts/SiennaBold/font.woff') format('woff');
}
@font-face {
  font-family: "Sienna-HeavyItalic";
  src: url('assets/webFonts/SiennaHeavyItalic/font.woff2') format('woff2'),
  url('assets/webFonts/SiennaHeavyItalic/font.woff') format('woff');
}
@font-face {
  font-family: "Orto-Light";
  src: url('assets/webFonts/OrtoLight/font.woff2') format('woff2'),
  url('assets/webFonts/OrtoLight/font.woff') format('woff');
}
@font-face {
  font-family: "Orto-LightItalic";
  src: url('assets/webFonts/OrtoLightItalic/font.woff2') format('woff2'),
  url('assets/webFonts/OrtoLightItalic/font.woff') format('woff');
}
@font-face {
  font-family: "Sienna-Heavy";
  src: url('assets/webFonts/SiennaHeavy/font.woff2') format('woff2'),
  url('assets/webFonts/SiennaHeavy/font.woff') format('woff');
}
@font-face {
  font-family: "Orto-Black";
  src: url('assets/webFonts/OrtoBlack/font.woff2') format('woff2'),
  url('assets/webFonts/OrtoBlack/font.woff') format('woff');
}
@font-face {
  font-family: "Orto-ExtraBoldItalic";
  src: url('assets/webFonts/OrtoExtraBoldItalic/font.woff2') format('woff2'),
  url('assets/webFonts/OrtoExtraBoldItalic/font.woff') format('woff');
}
@font-face {
  font-family: "Orto-ExtraBold";
  src: url('assets/webFonts/OrtoExtraBold/font.woff2') format('woff2'),
  url('assets/webFonts/OrtoExtraBold/font.woff') format('woff');
}
@font-face {
  font-family: "Orto-MediumItalic";
  src: url('assets/webFonts/OrtoMediumItalic/font.woff2') format('woff2'),
  url('assets/webFonts/OrtoMediumItalic/font.woff') format('woff');
}
@font-face {
  font-family: "Orto-BlackItalic";
  src: url('assets/webFonts/OrtoBlackItalic/font.woff2') format('woff2'),
  url('assets/webFonts/OrtoBlackItalic/font.woff') format('woff');
}
@font-face {
  font-family: "Sienna-MediumItalic";
  src: url('assets/webFonts/SiennaMediumItalic/font.woff2') format('woff2'),
  url('assets/webFonts/SiennaMediumItalic/font.woff') format('woff');
}
@font-face {
  font-family: "Orto-SemiBold";
  src: url('assets/webFonts/OrtoSemiBold/font.woff2') format('woff2'),
  url('assets/webFonts/OrtoSemiBold/font.woff') format('woff');
}
@font-face {
  font-family: "Orto-SemiBoldItalic";
  src: url('assets/webFonts/OrtoSemiBoldItalic/font.woff2') format('woff2'),
  url('assets/webFonts/OrtoSemiBoldItalic/font.woff') format('woff');
}
@font-face {
  font-family: "Orto-Italic";
  src: url('assets/webFonts/OrtoItalic/font.woff2') format('woff2'),
  url('assets/webFonts/OrtoItalic/font.woff') format('woff');
}
@font-face {
  font-family: "Sienna-BoldItalic";
  src: url('assets/webFonts/SiennaBoldItalic/font.woff2') format('woff2'),
  url('assets/webFonts/SiennaBoldItalic/font.woff') format('woff');
}
@font-face {
  font-family: "Orto-Thin";
  src: url('assets/webFonts/OrtoThin/font.woff2') format('woff2'),
  url('assets/webFonts/OrtoThin/font.woff') format('woff');
}
@font-face {
  font-family: "Orto";
  src: url('assets/webFonts/Orto/font.woff2') format('woff2'),
  url('assets/webFonts/Orto/font.woff') format('woff');
}
@font-face {
  font-family: "Orto-BoldItalic";
  src: url('assets/webFonts/OrtoBoldItalic/font.woff2') format('woff2'),
  url('assets/webFonts/OrtoBoldItalic/font.woff') format('woff');
}
@font-face {
  font-family: "Orto-Medium";
  src: url('assets/webFonts/OrtoMedium/font.woff2') format('woff2'),
  url('assets/webFonts/OrtoMedium/font.woff') format('woff');
}
@font-face {
  font-family: "Orto-Bold";
  src: url('assets/webFonts/OrtoBold/font.woff2') format('woff2'),
  url('assets/webFonts/OrtoBold/font.woff') format('woff');
}
footer.cookie-comply__modal-footer {
  background-color: #fff !important;
  border: 0 !important;
  padding: 0 !important;
  text-align: center;
}
</style>
