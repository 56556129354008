<template>
   <default>
  <div class="leistungen">
    <div class="cnthero">
    <div class="heroslogan">
      <h1 class="herohone">Das geschulte<br>Gehör</h1>
    </div>
    <div class="subhead">
      <h1 class="herohtwo"><a class="herolink" href="/kontakt">Kontaktaufnahme</a></h1>
    </div>
   <div id="scroll" style="margin: 0 auto;">
      <a @click="scrollToElement" style="position: absolute; bottom: 2em;"><img src="../assets/arrow.png" /></a>
  </div>
</div>
</div>
   <div class="main-kontent" style="width: 1440px; margin:0 auto">
    <div id="allCars" ref="targetRef">
<div class="foursquare">
  <div style="display: flex;">
    <div style="  width: 50%; display: flex; margin-right: 12%;">
    <img src="../assets/icon-fon.png" style="height: fit-content;"><p style="font-family: 'Orto-Bold'; line-height: 42px; color: #fff; font-size: 28px; margin: 0px 0 0 12px;">0171-4131710</p>
  </div>
  <div style="width: 38%; display: flex;">
    <img src="../assets/icon-envalope.png" style="height: fit-content;"><p style="font-family: 'Orto-Bold'; line-height: 42px; color: #fff; font-size: 28px; margin: 0px 0 0 12px;">KONTAKT</p>
  </div>
  </div>
<div style="text-align: left; margin-top: 4em;">
<img src="../assets/rede.png" />
</div>
  <div>
  <p style="font-family: 'Sienna-Bold', sans-serif; font-size: 36px; line-height: 60px; text-align: right; font-weight: 600; color: #fff; margin: 0;">Klaviere sind tatsächlich relativ empfindliche Instrumente</p>
<p style="font-family: 'Orto-Bold', sans-serif; font-size: 21px; text-align: right; font-weight: 600; color: #fff; text-align: right; margin: 0;">Arne Assmuth</p>
</div>
    </div>
    <div style="margin: 2em 2em;">
    <h1 style="text-align: left; font-size: 28px; font-family: 'Orto-Bold', sans-serif; margin: 0; line-height: 42px;">Meine Leistungen am Tasten- und Saiteninstrument</h1>
<h2 style="text-align: left; font-size: 21px; font-family: 'Orto-Bold', sans-serif; margin: 0; line-height: 30px;">Fachgerechtes Stimmen, Regulieren und Intunieren von Flügeln und Klavieren</h2>
<p style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; color: #000; line-height: 26px;">Auf den folgenden Seiten möchte ich Ihnen vorstellen, welche Dienstleistungen ich Ihnen anbiete.
  Bitte schauen Sie sich um und zögern Sie nicht, mich zu kontaktieren, wenn Sie noch Fragen haben.
</p>
<p style="text-align: left; font-family: 'Orto-Bold', sans-serif; font-size: 18px; color: #000; line-height: 26px;">Zum Ablauf: Die Terminabstimmung erfolgt telefonisch oder per Email.<br>
Sie erhalten von mir grundsätzlich Festpreisangebote inklusive aller Nebenleistungen wie z.B. Reiskosten.
  Sie wissen also immer vorher, welche Kosten auf Sie zukommen.
  </p>
<p style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; color: #000; line-height: 26px;">Bei aufwändigeren Reparaturanfragen erhalten Sie immer einen Kostenvorschlag, bevor ich tätig werde. Die Bezahlung erfolgt direkt vor Ort in Bar oder per EC-Karte. Für den Fall, dass ich teurere Ersatzteile für Sie beschaffen muss, behalte ich mir vor, eine Anzahlung zu erbitten.</p>
    </div>
  </div>
  <div style="display: grid; grid-template-columns: 1.2fr 1fr; margin: 8em 0 0 0; gap: 4em;">
<div class="firstimg" style="display: inline-grid; background-size: auto 620px; background-repeat: no-repeat; height: 520px; position:relative;">
<img src="../assets/tiles-r.png" style="position: absolute; height: 100%;">
<p style="font-family: 'Sienna-Medium', sans-serif; color:#fff; transform: rotate(270deg); font-size: 32px; margin: 4% 0 0 -50px; line-height: 32px; width: auto; position: absolute; top: 80px; letter-spacing: 0.05em; position: absolute;">STIMMEN</p>
<img src="../assets/klaviatur.png" style="bottom: 14px; position: absolute; opacity: 0.8;">
</div>
<div style="display: grid; grid-template-rows: auto 1fr;">
<div style="display: inline-grid; align-self: center;">
<h2 style="text-align: left; font-size: 24px; font-family: 'Orto-Bold', sans-serif; margin: 0; line-height: 32px;">Stimmen</h2>
<p style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 26px;">Der Saitenzug und Schwankungen im Raumklima sorgen dafür, dass sich das Klavier verstimmt.
Auch das Spielen führt zu Belastungen der Saiten und zerrt an der Stimmung. Wie ein Gitarrenspieler vor jedem Spielen müssen auch Sie ein bis zweimal im Jahr dafür sorgen, dass Ihr Klavier in Stimmung bleibt.
</p>
<p style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 26px;">Dazu komme ich regelmäßig zu Ihnen und führe die notwendigen Arbeiten an Ihrem Klavier durch. Und wenn Sie möchten, übernehme ich sogar die Terminplanung für Sie und melde mich stets rechtzeitig bei Ihnen.
</p>
</div>
<div>
  <div>
  <p style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 26px;">Ein Klavier ist ein komplexes Gebilde, das einiges aushalten muss.
  Die über 200 Saiten ziehen mit mehreren Tonnen am gusseisernen Rahmen, die Mechanik ist filigran und hoher Beanspruchung ausgesetzt und gleichzeitig spielen sich alle Bewegungen im Inneren auf engstem Raum ab: da können schon minimale Abweichungen zu Missklängen führen.</p>
</div>
</div>
</div>
  </div>
<div style="margin-top: 3em;">
<p style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 26px;"><strong>Die Behandlung Ihres Klavieres oder Flügels umfasst dabei folgende 3 Aspekte:</strong></p>
<li style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 26px;">> Klavierstimmung bedeutet, die Spannung der Saiten so einzustellen, dass sie klanglich zueinander passen.</li>
<li style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 26px;">> Regulieren bedeutet, die Mechanik so abzustimmen, dass das gewünschte Klangbild entsteht.</li>
<li style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 26px;">> Inklusive ist dabei immer eine fachgerechte Wartung und eine persönliche Beratung zur Pflege Ihres Instrumentes.</li>
</div>

  <div style="display: grid; grid-template-columns: 1fr 1.5fr; margin: 12em 0 0 0; grid-gap: 4em;">
    <div style="display: grid; grid-template-rows: auto 1fr;">
<div style="display: inline-grid; align-self: center;">
<h2 style="text-align: left; font-size: 24px; font-family: 'Orto-Bold', sans-serif; margin: 0; line-height: 32px;">Reparieren</h2>
<p style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 26px;">Klavierstimmung bedeutet, die Spannung der Saiten so einzustellen, dass sie klanglich zueinander passen.
  Aber nicht immer ist gleich etwas kaputt.
Häufig reichen ein paar Handgriffe an der richtigen Stelle, um Ihr Klavier wieder gängig zu machen.</p>
<p style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 26px;">In anderen Fällen kann es aber natürlich vorkommen, dass schadhafte Teile ersetzt werden müssen. Wirbel, Saiten oder Ham- merköpfe lassen sich schnell und preiswert reparieren. Solltes es doch einmal zu größeren Problemen kommen, erstelle ich Ihnen einen Kostenvoranschlag und wir stimmen das weitere Vorgehen in aller Ruhe ab.
</p>
<p style="text-align: left; font-family: 'Orto-Bold', sans-serif; font-size: 18px; line-height: 26px;">Aber eins ist sicher: bei uns, eingetragen als offizieller Fachbetrieb für Klavier- und Cembalobau, ist Ihr Klavier in besten Händen!
</p>
</div>
</div>
<div class="secondimg" style="display: inline-grid; background-size: auto 620px; background-repeat: no-repeat; height: 520px; position:relative; background-position: right top; justify-items: end;">
<img src="../assets/tiles-r.png" style="position: absolute; height: 100%;">
<p style="font-family: 'Sienna-Medium', sans-serif; color:#fff; transform: rotate(270deg); font-size: 32px; margin: 4% -88px 0px 0; line-height: 32px; width: auto; position: absolute; top: 80px; letter-spacing: 0.05em; position: absolute; text-transform: uppercase;">Reparieren</p>
<img src="../assets/klaviatur.png" style="bottom: 14px; position: absolute; opacity: 0.8; rotate: 180deg;">
</div>
  </div>
  <div style="display: grid; grid-template-columns: 1.5fr 1fr; margin: 12em 0 0 0; grid-gap: 4em;">
<div class="thirdimg" style="display: inline-grid; background-size: auto 620px; background-repeat: no-repeat; height: 520px; position:relative;">
<img src="../assets/tiles-r.png" style="position: absolute; height: 100%;">
<p style="font-family: 'Sienna-Medium', sans-serif; color:#fff; transform: rotate(270deg); font-size: 32px; margin: 8% 0px 0px -100px; line-height: 32px; width: auto; position: absolute; top: 80px; letter-spacing: 0.05em; position: absolute; text-transform: uppercase;">Begutachten</p>
<img src="../assets/klaviatur.png" style="bottom: 14px; position: absolute; opacity: 0.8;">
</div>
<div style="display: grid; grid-template-rows: auto 1fr;">
<div style="display: inline-grid; align-self: center;">
<h2 style="text-align: left; font-size: 24px; font-family: 'Orto-Bold', sans-serif; margin: 0; line-height: 32px;">Begutachten</h2>
<p style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 26px;">Nicht selten sind Klaviere und Flügel 100 Jahre und älter und kaum ein stolzer Besitzer würde sein Instrument gegen ein neues austauschen.
Aus gutem Grund: der warme, individuelle Klang, die schöne Verarbeitung und der eigenständige Charakter machen die alten Schätze so attraktiv.
Aber wie kann man ein solches Instrument erstehen, ohne die Katze im Sack kaufen zu müssen? Sie haben das Instrument Ihrer Träume gefunden und wollen nun wissen, ob es auch sein Geld wert ist?
Rufen Sie einfach an!</p>
<p style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 26px;">
Ich begutachte für Sie das Klavier (oder den Flügel) und nehme es fachgerecht unter die Lupe. Sollten Mängel erkennbar sein, kann ich Ihnen in der Regel direkt vor Ort erläutern, ob sich eine Reparatur lohnt und welche Kosten auf Sie zukommen würden. So können Sie sich sicher sein, dass Ihre Anschaffung in Ordnung ist und Sie keine unangenehmen Überraschungen erleben.</p>
</div>
</div>
  </div>

  <div style="display: grid; grid-template-columns: 1.0fr 1.5fr; margin: 12em 0 0 0; grid-gap: 6em;">
<div class="betrieb" style="display: inline-grid; grid-template-rows: 1fr 1fr; position: relative;">
  <div style="display: inline-grid; justify-items: end;">
  <img src="../assets/icon-nkey.png" style="height: 6em;"/>
</div>
<div>
<h2 style="font-family: 'Sienna-Bold', sans-serif; color:#2c3e50; font-size: 36px; line-height: 50px; width: auto; letter-spacing: 0.05em; text-align: right;">Klavierunterricht</h2>
</div>
</div>
<div style="display: grid; grid-template-rows: auto 1fr;">
<div style="display: inline-grid; align-self: center;">
<h2 style="text-align: left; font-size: 24px; font-family: 'Orto-Bold', sans-serif; margin: 0; line-height: 32px;">Es gibt ein paar Grundsätze zum Erlernen des Klavierspielens</h2>
<p style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 26px;">
Als langjähriger Schüler von Macej Lukaszczyk (gestorben 2014) (Begründer der Chopin -Gesellschaft i.d. Bundesrepublik Deutschland e.V.),
unterrichte ich Klavierschüler in allen Altersklassen.</p>
<p style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 26px;">	
Sowohl Anfänger als auch ambitionierte Hobbypianisten kommen auf ihre Kosten und werden mit Freude Ihre eigenen Fortschritte beobachten.
Das Pensum legen Sie fest. Das wichtigste ist, dass Sie Spaß am Unterricht haben und motiviert Ihre Übungen durchführen. Durch eine breite Auswahl an Noten und Stilrichtungen ist immer ein passendes Stück für Ihren persönlichen Geschmack und Ihre Leistungsstufe dabei. Im gesamten Rhein-Main-Gebiet kann bei Ihnen zu Hause unterrichtet werden oder in einer nahe gelegenen Musikschule.
</p>
</div>
</div>
  </div>
  
  <div style="display: grid; grid-template-columns: 1.2fr 0.8fr; gap: 6em; margin: 6em 0;">
    <div style="display: inline-grid; align-content: center;">
  <p style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 26px;">Durch meine vielen Kunden und Kontakte kann ich Ihnen sicher schnell weiterhelfen. Schauen Sie auch regelmäßig auf meiner Seite nach.
  </p>
  <p style="text-align: left; font-family: 'Orto', sans-serif; font-size: 18px; line-height: 26px;">Unter der Rubrik Aktuelles platziere ich aktuelle Gesuche und Angebote.</p>
</div>
<div style="background-color: #32a852; padding: 2em;">
  <h2 style="font-family: 'Sienna-Bold; font-size:36px ;color:#fff;">Klaviere und Flügel vermitteln</h2>

  <p style="text-align: left; font-family: 'Orto-Bold', sans-serif; font-size: 21px; line-height: 26px; color: #fff;">Sie suchen ein Saiteninstrument oder wollen Ihr bisheriges abgeben?
  </p><p style="text-align: left; font-family: 'Orto-Bold', sans-serif; font-size: 21px; line-height: 26px; color: #fff;">Bitte sprechen Sie mich an.</p>
</div>
</div>
<div class="overroll">
<div class="zweier">
	<h1 style="font-family: 'Sienna-Bold', serif;"><span class="n-alternate">N</span>ehmen <span class="s-alternate">S</span>ie <span class="k-alternate">K</span>ontakt <span class="a-alternate">A</span>UF und <span class="l-alternate">l</span>assen Sie Ihr <span class="k-alternate">K</span>lavier <span class="s-alternate">S</span>ichten</h1>
</div>
<div style="width: 30%; float: left; margin-left: 6%; margin-right: 4%; margin-top: 28px;">
<div style="width: 20%; float: left;"><img src="../assets/hoerer-g.png" style="width:60px;"></div>
<div style="width: 100%;"><span style="font-family: 'Orto-Bold', sans-serif; font-size: 26px; line-height:38px; color: #000;">Telefon</span><br>
<span style="font-family: 'Orto-Medium', sans-serif; font-size: 26px; line-height:38px; color: #000;">+49 (0)170 - 4131 710</span></div>
</div>
<div style="width: 28%; float: left; margin-right: 2%; margin-top: 28px;">
<div style="width: 20%; float: left;"><img src="../assets/formular-g.png" style="width:60px;"></div>
<div style="width: 100%;"><a style="text-decoration: none; color: #666666;" href="#/kontakt"><span style="font-family: 'Orto-Bold', sans-serif; font-size: 26px; line-height:38px; color: #000;">Kontaktformular</span><br>
<span style="font-family: 'Orto-Medium', sans-serif; font-size: 26px; line-height:38px; color: #000;">Termin anbieten</span></a></div>
</div>
<div style="width: 28%; float: left; margin-right: 0%; margin-top: 28px;">
<div style="width: 20%; float: left;"><img src="../assets/at-g.png" style="width:60px;"></div>
<div style="width: 100%;"><span style="font-family: 'Orto-Bold', sans-serif; font-size: 26px; line-height:38px; color: #000;">E-Mail</span><br>
<span style="font-weight: bold; font-size: 14px;"><a style="font-family: 'Orto-Medium', sans-serif; font-size: 19px; line-height: 38px; display: inline; color: #000;" href="mailto:info@klavierstimmen-braunschweig.de">klavierstimmen@braunschweig</a></span></div>
</div>
</div>

  </div>
  </default>
</template>

<script>
export default {
  methods: {
    scrollToElement() {
      const element = document.getElementById('allCars');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }
}
</script>
<style>
.leistungen {
  background-image: url('../assets/leistungen.jpg');
  height: 85vH;
  background-size: cover;
  background-position: center;
  margin: 0 auto;
  display: grid;
  /*
  margin-left: calc((100% - 1440px) / 2); /* Berechnung des Überschusses über die 1440px-Seitenbreite */
  /* margin-right: calc((100% - 1440px) / 2); /* Berechnung des Überschusses über die 1440px-Seitenbreite */
}
</style>